import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { RecordingPage } from './components/RecordingPage';
import { RecordingsPage } from './components/RecordingsPage';
import { PatternsPage } from './components/PatternsPage';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/recordings' component={RecordingsPage} />
        <Route path='/patterns' component={PatternsPage} />
        <Route path={"/recording/:id"} component={RecordingPage} />
      </Layout>
    );
  }
}
