import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label as ReactStrap_Label } from 'reactstrap';
import { RecordingRenderHelper } from './RecordingRenderHelper';
import { Utils } from './Utils';



export class PatternsPage extends Component {
  static displayName = PatternsPage.name;

  constructor(props) {
    super(props);
    this.state = {
      patterns: [],
      dataForRendering: {
        items: []
      },
      modalDeleteVisible: false,
      modalDeleteId: null,
      loading: true
    };
  }

  componentDidMount() {
    this.populatePatterns();
  }



  handleDeleteBtnClick = async (id) => {
    this.setState({
      modalDeleteId: id,
    });
    this.handleShowHideDeletePatternDlg();
  }

  handleShowHideDeletePatternDlg = (e) => {
    this.setState({
      modalDeleteVisible: !this.state.modalDeleteVisible
    });
  }


  handleModalDeleteOkClick = async (id) => {
    this.handleShowHideDeletePatternDlg();

    let url = `${Utils.getApiBaseUrl()}/patterns/${this.state.modalDeleteId}`;
    this.setState({
      modalDeleteId: null,
    });


    try {
      const response = await fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      });
      this.populatePatterns();
    }
    catch {
      alert(`Failed deleting pattern ${this.state.modalCreatePatternName}`);
    }
  }

  renderRecordingLink(x) {
    let strTo = `/recording/${x.recording.id}?patternId=${x.parentPattern.id}`;


    return <Link className="btn btn-primary" to={strTo}>{`(${x.recording.id}) ${x.recording.name}`}</Link>
  }

  renderBtnDelete(x) {
    if (x.pattern) {
      return (
        <button className="btn btn-primary" onClick={() => this.handleDeleteBtnClick(x.pattern?.id)} >Delete</button>
      );
    }
    else {
      return null;
    }
  }

  renderPatternsTable(samples) {

    let tableData = this.state.dataForRendering.items.map((pattern) => {
      let data = pattern.sources ?? [];

      if (!data.length) {
        data.push({})
      }

      data[0]["pattern"] = pattern;
      data.forEach(data => {
        data["parentPattern"] = pattern;
      });
      return data;
    });
    tableData = tableData.flat();

    let i = 0;



    return (
      <table className='table table-striped' aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th>Id</th>
            <th>Delete</th>
            <th>Name</th>
            <th>Prop name</th>
            <th>Prop value</th>
            <th>Recordings</th>
            <th>Ranges</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(x =>
            <tr key={`${x.parentPattern.id}-${x.recording.id}`}>
              <td>{x.pattern?.id}</td>
              <td>{this.renderBtnDelete(x)}</td>
              <td>{x.pattern?.name}</td>
              <td>
                <div>lowerFrequency</div>
                <div>upperFrequency</div>
                <div>windowType</div>
                <div>matchLimit</div>
                <div>minMatchCount</div>
                <div>maxMatchCount</div>
              </td>
              <td>
                <div>{x.parentPattern?.lowerFrequency}</div>
                <div>{x.parentPattern?.upperFrequency}</div>
                <div>{x.parentPattern?.windowType}</div>
                <div>{x.parentPattern?.matchLimit}</div>
                <div>{x.parentPattern?.minMatchCount}</div>
                <div>{x.parentPattern?.maxMatchCount}</div>
              </td>

              <td>
                {this.renderRecordingLink(x)}
                {RecordingRenderHelper.renderUserAnnotations(x.recording)}
              </td>
              <td>{(x.sampleRanges ?? []).map((y) =>
                <div>{y.fromSample}&nbsp;-&nbsp;{y.fromSample + x.parentPattern.samplesPerWindow}</div>
              )}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  createDataForRendering(data) {
    return data;
  }





  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderPatternsTable(this.state.dataForRendering);

    return (
      <div>
        <Modal isOpen={this.state.modalDeleteVisible} toggle={this.handleShowHideDeletePatternDlg} >
          <ModalHeader toggle={this.handleShowHideDeletePatternDlg}>
            Delete pattern
          </ModalHeader>
          <ModalBody>
            <ReactStrap_Label >Pattern to delete: {this.state.modalDeleteId}</ReactStrap_Label>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleModalDeleteOkClick} className="mr-1">
              Ok
            </Button>
            <Button onClick={this.handleShowHideDeletePatternDlg}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <h1 id="tableLabel" >{Utils.getProjectUiName()} patterns</h1>
        <p>Patterns to match recorded sounds.</p>
        {contents}
      </div>
    );
  }

  async populatePatterns() {
    var uri = `${Utils.getApiBaseUrl()}/patterns`;

    try {
      const response = await fetch(uri);

      //const data1 = await response.text();

      const data = await response.json();
      let dataForRendering = this.createDataForRendering(data);
      this.setState({
        patterns: data,
        dataForRendering: dataForRendering,
        loading: false
      });
    }
    catch (ex) {
      alert(`Failed loading recordings ${ex}`);
    }
  }
}
