import React, { Component } from 'react';
import { RecordingPlayButton } from './RecordingPlayButton';
import { Link } from "react-router-dom";
import { UserMgr } from "./UserMgr";
import { Utils } from './Utils';
import { LoadingDialog } from './LoadingDialog';
import { RecordingRenderHelper } from './RecordingRenderHelper';




export class RecordingsPage extends Component {
  static displayName = RecordingsPage.name;
  _user = new UserMgr().getCurrentUser();

  static loadingDialogTextDefault = "Please wait while loading data...";

  constructor(props) {
    let amplitudeLessThen = 300;
    //1/20  20Hz -> 50ms
    let amplitudeLessThenDuration = 1/40;

    super(props);
    this.state = {
      recordings: [],
      showPatternAnnotationForIds: [1],
      patternIdsToPatterns: [],
      loadingDialogText: this.loadingDialogTextDefault,
      deleteLogsArray: null,
      commentWanted: "",
      strAmplitudeLessThen: "" + amplitudeLessThen,
      amplitudeLessThenDuration: amplitudeLessThenDuration,
      strAmplitudeLessThenDuration: "" + amplitudeLessThenDuration,
      //set this to a value if you default want to check a pattern
      patternIdToLoad: 1
    };

  }

  

  componentDidMount() {
    this.setState({
      loadingDialogText: this.loadingDialogTextDefault,
    });
    this.populateRecordings();
  }

  renderDate(recording) {
    let strDate = Utils.strUtfDateTimeToLocalStrDate(recording.dateCreatedUtc);
    let strTime = Utils.strUtfDateTimeToLocalStrTime(recording.dateCreatedUtc);
    return (
      <>
        <div>{strDate}</div>
        <div className="font-weight-bold">{strTime}</div>
      </>
    );
  }


  
  renderDevice(recording) {
    return (
      <div className="w-6em">
        <i className={`fas fa-microphone text-success`} ></i><span className="ml-1">{recording?.device?.id}-{recording?.device?.name}</span>
      </div>
    );
  }

  renderMismatch(recording) {

    let matchTypes = ["isDiffedWithIntensityMatch"];

    let patternAnnotation = recording?.annotations?.find(y => y?.patternId == 1);
    let userAnnotation = recording?.annotations?.find(y => y?.user);

    let renderedResult = matchTypes.map(matchType => {
      let incorrectVerdict = (patternAnnotation?.[matchType] && userAnnotation?.isMatch === false)
        || (patternAnnotation?.[matchType] === false && userAnnotation?.isMatch === true);

      let retVal = (<div></div>);
      if (incorrectVerdict) {
        retVal = (
          <>
            <i className={`fas fa-frown text-warning ` } title="Mismatched"></i><span className="ml-1 tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"></span>
          </>
        );
      }

      return retVal;
    });


    return renderedResult;
  }



  renderPatternAnnotations(recording) {
    return (recording.annotations ?? []).filter(x => (this.state.showPatternAnnotationForIds.filter(y => y == x.patternId).length ?? true))
      .map(z => {
        let url = `/recording/${encodeURIComponent(recording.id)}?patternId=${encodeURIComponent(z.patternId)}`;
        let strClasses = z.isMatch ? "text-success w-100" : "text-danger";
        let strDiffedClasses = z.isDiffedMatch ? "text-success w-100" : "text-danger";
        let strDiffedWithIntensityClasses = z.isDiffedWithIntensityMatch ? "text-success w-100" : "text-danger";

        let pattern = this.state.patternIdsToPatterns[z.patternId];

        return (
          <div className={strClasses}>
            <Link to={url}>{pattern.name}</Link>:&nbsp;{z.isMatch ? '+' : '-'}
            <div className={strClasses}> &nbsp;ME:&nbsp;{z.minErrorLevel?.toFixed(2)}&nbsp;C:&nbsp;{z.matchCount}</div>
            <div className={strDiffedClasses}>&nbsp;MinDE:&nbsp;{z.diffedMinErrorLevel?.toFixed(2)}&nbsp;DCnt:&nbsp;{z.diffedMatchCount}</div>
            <div className={strDiffedWithIntensityClasses}>&nbsp;MinDE:&nbsp;{z.diffedWithIntensityMinErrorLevel?.toFixed(2)}&nbsp;DCnt:&nbsp;{z.diffedWithIntensityMatchCount}</div>
          </div>
        );
      });
  }

  handleClickDelete = async (e) => {
    let recordingIds = this.recordingIdsFromBtnOrChkBoxes(e, "delete recording");

    let countTriedDelete = 0;


    if (recordingIds.length) {
      //get all that are checked to array
      let deleteLogsArray = await Promise.all(recordingIds.map(async recordingId => {
        try {
          var url = `${Utils.getApiBaseUrl()}/recordings/${recordingId}`;

          let response = null;
          response = await fetch(url, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
              'Content-Type': 'application/json'
            },
          });

          let deleteLogs = await response.json();

          countTriedDelete++;

          let message = `Trying to delete recording (${countTriedDelete}/${recordingIds.length}) ...`

          this.setState({ loadingDialogText: message });

          return deleteLogs;

        }
        catch (e) {
          //TODO: should return the deleteLogs from the server if they exist
          let strError = (`RecordingsPage Failed deleting recording ${recordingId}`);
          let deleteLogs = { items: [{ id: recordingId, log: strError }] };
          return deleteLogs;
        }
      }));

      this.setState({
        deleteLogsArray: deleteLogsArray,
        loadingDialogText: null,
      });

      let strDeleteLogs = deleteLogsArray?.map(x => x.items).flat().map((x) => {
        return `Id: ${x.id} Log: ${x.log}`;
      }).join("\r\n");
      alert(strDeleteLogs);

      this.populateRecordings(null);
    }
  }

  handleBtnClickPlay = (e) => {
    let recordingId = e.target.value;
    //get the one that matches clicked recording
    let chkRecording = Array.prototype.slice.call(document.getElementsByClassName("chkRecording"))
      .find(x => x?.value == recordingId);
    chkRecording.checked = true;
  }

  fetchOrCreateUserAnnotation = async (recordingId) => {

    let url = `${Utils.getApiBaseUrl()}/recordings/${encodeURI(recordingId)}`;
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    });
    let recording = await response.json();


    //see if we find one that matches otherwise create new
    let annotation = recording.annotations?.find(x => x.user?.id == this._user.id) ?? ({
      id: 0,
      user: { id: this._user.id },
      isMatch: null
    });
    return annotation;
  }


  async postUserAnnotation(recordingId, annotation) {
    let url = `${Utils.getApiBaseUrl()}/recordings/${encodeURI(recordingId)}/annotations/${annotation?.id}`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(annotation),
    });

    annotation = await response.json();
    return annotation;
  }


  handleClickSetAnnotated_true = async (e) => { await this.handleClickSetAnnotated(e, true);}
  handleClickSetAnnotated_false = async (e) => { await this.handleClickSetAnnotated(e, false);}
  handleClickSetAnnotated_null = async (e) => { await this.handleClickSetAnnotated(e, null);}


  async handleClickSetAnnotated(e, isMatch)
  {
    let recordingIds = this.recordingIdsFromBtnOrChkBoxes(e, "change match verdict");

    if (recordingIds.length) {
      if (recordingIds.length != 1) {
        alert("Exactly one must be marked when setting as match");
        return;
      }

      let recordingId = recordingIds[0];
      try {

        let annotation = await this.fetchOrCreateUserAnnotation(recordingId);
        annotation.isMatch = isMatch;
        await this.postUserAnnotation(recordingId, annotation);

        this.populateRecordings(false);
      }
      catch (e) {
        alert(`Failed setting as matched ${recordingId}`);
      }

    }
  }

  handleClickDeleteAnnotation = async (e) => {
    let recordingIds = this.recordingIdsFromBtnOrChkBoxes(e, "delete annotation");

    if (recordingIds.length) {
      if (recordingIds.length != 1) {
        alert("Exactly one must be marked when setting as match");
        return;
      }



      let recordingId = recordingIds[0];

      //find recording
      let recording  = this.state.recordings.items.find(x => {
        //find annotation belonging to this recording that has a user
        return x.id == recordingId;
      });

      //find annotation
      let annotation = recording.annotations.find(y => { return (y.user != null) });

      try {

        let url = `${Utils.getApiBaseUrl()}/recordings/${encodeURI(recordingId)}/annotations/${annotation?.id}`;

        const response = await fetch(url, {
          method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Content-Type': 'application/json'
          },
        });

        annotation = await response.json();

        this.populateRecordings(false);
      }
      catch (e) {
        alert(`Failed deleting recording annotation ${recordingId}/${annotation.id}`);
      }

    }
  }



  handleClickUnselect = () => {
    let chkRecordings = Array.prototype.slice.call(document.getElementsByClassName("chkRecording"));
    chkRecordings.forEach(x => { x.checked = false; });
  }

  handleBtnClickAction = async (e) => {
    let recordingId = e.target.dataset.id;
    let recording = this.state.recordings.items.find(x => x.id == recordingId);
    let annotation = recording?.annotations?.find(x => x?.user != null);

    let inputComment = document.getElementsByClassName(`input-comment-${recordingId}`)[0];
    inputComment.value = annotation?.comment ?? "";
    this.setState({ commentWanted: annotation?.comment ?? "" });
  }

  recordingIdsFromBtnOrChkBoxes(e, confirmActionName) {
    let recordingIds = [];

    if (e.target?.value) {
      //do it on single item
      recordingIds.push(e.target?.value);
    }
    else {
      //do it on multiple items
      let chkRecordings = document.getElementsByClassName("chkRecording");
      recordingIds = Array.prototype.slice.call(chkRecordings).filter(x => x?.checked).map(x => x.value);
    }

    if (confirmActionName) {
      if (!window.confirm(`Do you really want to:  ${confirmActionName} on recording(s):\r\n${recordingIds.join('\r\n')}`)) {
        recordingIds = [];
      }
    }
    return recordingIds;
  }

  handleBtnClickMatchWithPatterns = async (e) => {

    let recordingIds = this.recordingIdsFromBtnOrChkBoxes(e, "match with pattern");
    this.setState({
      loadingDialogText: "Please wait while matching...", 
    });

    recordingIds.forEach(async (recordingId) => {
      let url = `${Utils.getApiBaseUrl()}/recordings/${recordingId}?matchWithPatterns=true`;

      try {
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: parseInt(recordingId) }),
        });

        const recording = await response.json();

        this.populateRecordings(false);
      }
      catch (e) {
        alert(`Failed match pattern ${recordingId}`);
      }
    });
  }

  handleBtnClickComment = async (e) => {

    let recordingIds = this.recordingIdsFromBtnOrChkBoxes(e, "set comment on pattern");

    if (recordingIds.length) {
      if (recordingIds.length != 1) {
        alert("Exactly one must be marked when setting a comment");
        return;
      }

      let recordingId = recordingIds[0];
      try {

        let annotation = await this.fetchOrCreateUserAnnotation(recordingId);
        annotation.comment = this.state.commentWanted;
        await this.postUserAnnotation(recordingId, annotation);

        this.populateRecordings(false);
      }
      catch (e) {
        alert(`Failed setting comment on ${recordingId}`);
      }

    }

  }

  handleInputChangeComment = async (e) => {

    let recordingIds = this.recordingIdsFromBtnOrChkBoxes(e, null);
    this.setState({ commentWanted: e.target.value })
  }

  handleChkClickToggleAll = async (e) => {
    let chkRecordings = document.getElementsByClassName("chkRecording");
    chkRecordings = Array.prototype.slice.call(chkRecordings);
    if (chkRecordings.length) {
      let checked = chkRecordings[0].checked;
      chkRecordings.forEach(x => {
        x.checked = !checked;
      });
    }
  }



  renderRecordings(recordings) {
    return (
      <table className='table table-sm table-striped' aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th>Id</th>
            <th colSpan="2">
              <input className="btn btn-primary ml-1 mr-1" type="checkbox" onClick={this.handleChkClickToggleAll} />

            </th>
            <th>User Matched</th>
            <th>Play</th>
            <th>Date</th>
            <th>Name</th>
            <th>Annotations</th>
          </tr>
        </thead>
        <tbody>
          {(recordings?.items ?? []).map((x,i) => {
            let dateTime = new Date(x.dateCreatedUtc);
            let prevDateTime = ((i - 1) >= 0) ? new Date(recordings.items[i - 1]?.dateCreatedUtc) : dateTime;
            let dayOfMonthIsChanging = false;
            if (dateTime.getDate() != prevDateTime.getDate()) {
              dayOfMonthIsChanging = true;
            }
            let tdDayOfMonthIsChangingStyle = dayOfMonthIsChanging ? { borderTopColor: 'black', borderTopWidth: (dayOfMonthIsChanging ? 5 : 1) } : {};

            let searchStr = (this.state.patternIdToLoad != null) ? `?patternId=${this.state.patternIdToLoad}` : "";

            //let comment = this.fetchOrCreateUserAnnotation() x.a


            return (
              <tr style={{ borderTopWidth: (dayOfMonthIsChanging ? 5 : 1)}} key={x.id} >
                <td>
                  {x.id}
                  {this.renderDevice(x)}
                </td>
                <td style={tdDayOfMonthIsChangingStyle}>
                  <input className="btn btn-primary chkRecording ml-1 mr-1" type="checkbox" value={x.id} />
                  {this.renderMismatch(x)}
                </td>
                <td style={tdDayOfMonthIsChangingStyle}>
                  <div className="dropdown show">
                    <a className={`btn btn-primary btn-sm  dropdown-toggle`} onClick={this.handleBtnClickAction} data-id={x.id} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Action
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <button className="dropdown-item" value={x.id} onClick={this.handleClickDelete}>Delete</button>
                      <div className="dropdown-divider"></div>
                      <button className="dropdown-item" value={x.id} onClick={this.handleClickSetAnnotated_true}>Annotate as match</button>
                      <div className="dropdown-divider"></div>
                      <button className="dropdown-item" value={x.id} onClick={this.handleClickSetAnnotated_false}>Annotate as NOT match</button>
                      <div className="dropdown-divider"></div>
                      <button className="dropdown-item" value={x.id} onClick={this.handleClickSetAnnotated_null}>Remove match annotation</button>
                      <div className="dropdown-divider"></div>
                      <button className="dropdown-item" value={x.id} onClick={this.handleClickDeleteAnnotation}>Delete user annotation</button>
                      <div className="dropdown-divider"></div>
                      <button className="dropdown-item" value={x.id} onClick={this.handleBtnClickMatchWithPatterns}>Match selected with patterns</button>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href={`${Utils.getApiBaseUrl()}/Recordings/${x.id}/flake`} download>Download flake</a>
                      <div className="dropdown-divider"></div>
                      <div className="dropdown-item"><button className="btn btn-sm btn-primary w-100" value={x.id} onClick={this.handleBtnClickComment}>Set comment</button></div>
                      <div className={`dropdown-item input-comment-${x.id}`}><input className="input" placeholder="Enter cmt here..." value={this.state.commentWanted} onChange={this.handleInputChangeComment} /></div>
                    </div>
                  </div>
                  <div className="dropdown show mt-1">
                    <a className="btn-primary btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Checked Action
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" onClick={this.handleClickUnselect}>Unselect all</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" onClick={this.handleClickDelete}>Delete</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" onClick={this.handleBtnClickMatchWithPatterns}>Match selected with patterns</a>
                    </div>
                  </div>

                </td>
                <td>
                  {RecordingRenderHelper.renderUserAnnotations(x)}
                </td>
                <td><RecordingPlayButton className="form-control-sm btn-primary" value={x.id} id={x.id} onClick={this.handleBtnClickPlay} >Play</RecordingPlayButton></td>
                <td style={{ whiteSpace: "nowrap" }}>{this.renderDate(x)}</td>
                <td>
                  <Link to={`/recording/${x.id}${searchStr}`}>{x.name}</Link>
                  <div className="font-weight-bold" >
                    SR: {(x.orgSampleRate != x.sampleRate) ? `${x.orgSampleRate} -> ${x.sampleRate}` : `${x.sampleRate}`}
                    &nbsp; &nbsp; SPS: {x.samplesPerSample}
                    &nbsp; &nbsp; BPS: {x.bytesPerSample}
                  </div>
                </td>
                <td>{this.renderPatternAnnotations(x)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }


  handleBtnClickReload = () => {
    this.setState({
      loadingDialogText: this.loadingDialogTextDefault,
    });
    this.populateRecordings(false);
  }


  handleBtnClickMatchAllWithPatterns = () => {
    this.setState({
      loadingDialogText: "Please wait while matching patterns...",
    });
    this.populateRecordings(true);
  }

  handleBtnClickAmplitudeLessThen = async (e) => {
    let amplitudeLessThen = parseInt(this.state.strAmplitudeLessThen);
    let amplitudeLessThenDuration = parseFloat(this.state.strAmplitudeLessThenDuration);

    this.setState({
      loadingDialogText: `Please wait while looking for reqordings with amplitude less than ${amplitudeLessThen} during ${amplitudeLessThenDuration}`,
    });


    if (amplitudeLessThen == NaN) {
      alert(`Limit is not a number: ${this.state.strAmplitudeLessThen}`);
      return;
    }
    if (amplitudeLessThenDuration == NaN) {
      alert(`Limit duration is not a number: ${this.state.strAmplitudeLessThenDuration}`);
      return;
    }

    if (amplitudeLessThenDuration <= 0) {
      alert(`Limit duration is too small: ${this.state.strAmplitudeLessThenDuration}`);
      return;
    }

    let maxDuration = 1;
    if (amplitudeLessThenDuration >= maxDuration) {
      alert(`Limit duration is too large (above ${maxDuration} seconds): ${ this.state.strAmplitudeLessThenDuration }`);
      return;
    }




    let sumChecked = 0;

    let chkRecordings = document.getElementsByClassName("chkRecording");
    chkRecordings = Array.prototype.slice.call(chkRecordings);

    chkRecordings = chkRecordings.filter((x, i) => !x.checked && i < 500);
    chkRecordings = chkRecordings.map(async (x) => {
      let recordingId = x.value;
      let url = `${Utils.getApiBaseUrl()}/api/recordings/${encodeURI(recordingId)}?dataCount=10&sampleFrom=0&calcMaxMeanAmplitudeDuration=${amplitudeLessThenDuration}`;
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      });
      let recording = await response.json();

      let amplitude = recording.soundData.maxMeanAmplitude;
      let result = amplitude < amplitudeLessThen;
      if (result) {
        sumChecked++;
      }
      x.checked = result;
      return x;
    });

    await Promise.all(chkRecordings);
    alert(`Found: ${sumChecked}`);

    this.setState({
      amplitudeLessThenDuration: amplitudeLessThenDuration,
      loadingDialogText: null
    });


  }

  handleInputChangeAmplitudeLessThen = async (e) => {
    this.setState({
      strAmplitudeLessThen: e.target?.value ?? "",
    });
  }

  handleInputChangeAmplitudeLessThenDuration = async (e) => {
    this.setState({
      strAmplitudeLessThenDuration: e.target?.value ?? "",
    });
  }

  renderResultSummary = () => {
    let matchTypes = ["isMatch", "isDiffedMatch", "isDiffedWithIntensityMatch"];

    let renderedResult = matchTypes.map( (x,i) => {

      let item = {
        type: x,
        totalMatches: 0,
        totalCorrectIsMatch: 0,
        totalCorrectIsMatchByUser: 0,
        totalCorrectIsNotMatch: 0,
        totalCorrectIsNotMatchByUser: 0,
        incorrectVerdict: 0,
        percentCorrect: 0,
      };


      item.totalMatches = this.state.recordings?.items?.filter((x, i) => {
        let annotation = x?.annotations?.find(y => y?.patternId == 1);
        return annotation?.[item.type] && !(annotation?.user);
      }).length;

      item.totalCorrectIsMatch = this.state.recordings?.items?.filter((x, i) => {
        let patternAnnotation = x?.annotations?.find(y => y?.patternId == 1);
        let userAnnotation = x?.annotations?.find(y => y?.user);
        return patternAnnotation?.[item.type] && userAnnotation?.isMatch;
      }).length;

      item.totalCorrectIsMatchByUser = this.state.recordings?.items?.filter((x, i) => {
        let userAnnotation = x?.annotations?.find(y => y?.user);
        return userAnnotation?.isMatch;
      }).length;


      item.totalCorrectIsNotMatch = this.state.recordings?.items?.filter((x, i) => {
        let patternAnnotation = x?.annotations?.find(y => y?.patternId == 1);
        let userAnnotation = x?.annotations?.find(y => y?.user);
        return patternAnnotation?.[item.type] === false && userAnnotation?.isMatch === false;
      }).length;

      item.totalCorrectIsNotMatchByUser = this.state.recordings?.items?.filter((x, i) => {
        let userAnnotation = x?.annotations?.find(y => y?.user);
        return userAnnotation?.isMatch === false;
      }).length;

      item.incorrectVerdict = this.state.recordings?.items?.filter((x, i) => {
        let patternAnnotation = x?.annotations?.find(y => y?.patternId == 1);
        let userAnnotation = x?.annotations?.find(y => y?.user);
        return (patternAnnotation?.[item.type] && userAnnotation?.isMatch === false) || (patternAnnotation?.[item.type] === false && userAnnotation?.isMatch === true);
      }).length;

      item.percentCorrect = (100 * (item.totalCorrectIsMatch + item.totalCorrectIsNotMatch)) / (item.totalCorrectIsMatchByUser + item.totalCorrectIsNotMatchByUser)

      return (
        <div key={i}><button style={{ width: '20em' }}>
          &nbsp; {item.type}: {item.totalMatches}</button>
          &nbsp;&nbsp; Correct Is-Match: {item.totalCorrectIsMatch}/{item.totalCorrectIsMatchByUser}
          &nbsp;&nbsp; Correct IsNot-Match: {item.totalCorrectIsNotMatch}/{item.totalCorrectIsNotMatchByUser}
          &nbsp;&nbsp; Incorrect verdict: {item.incorrectVerdict}
          &nbsp;&nbsp; Correct verdict : {item.percentCorrect.toFixed(1)} %
        </div>
      );

    })

    return renderedResult;
  }

  render() {

    let title = `${Utils.getProjectUiName()} - Recordings`;
    document.title = title;

    return (
      <>
        <LoadingDialog show={this.state.loadingDialogText != null} message={this.state.loadingDialogText} />

        <div>
          <h1 id="tableLabel" >{`${Utils.getProjectUiName()} recordings`}</h1>
          {this.renderResultSummary()}
          <fieldset className="border rounded border-primary p-1">
            <legend>Recordings</legend>
            <div>
              <button className="btn btn-primary btn-sm mt-2 mr-2" onClick={this.handleBtnClickReload} >Reload</button>
              <button className="btn btn-primary btn-sm mt-2 mr-2" onClick={this.handleBtnClickMatchAllWithPatterns} >Match all with patterns</button>
              <span className="text-nowrap mr-2 mt-2" >
                <button className="btn btn-primary btn-sm mr-1" onClick={this.handleBtnClickAmplitudeLessThen}>Check all with max amplitude less than -&gt; </button>
                <input className="w-4em mr-1" type="text" value={this.state.strAmplitudeLessThen} onChange={this.handleInputChangeAmplitudeLessThen} />
                <label className="mr-1">over</label>
                <input className="ml-1 w-4em  mr-1" type="text" value={this.state.strAmplitudeLessThenDuration} onChange={this.handleInputChangeAmplitudeLessThenDuration} />
                <span className="">seconds ({(1 / this.state.amplitudeLessThenDuration).toFixed(2)} Hz)</span>
              </span>

              <div>
                {/*this.state.deleteLogsArray?.map(x => x.items).flat().map((x) => {
                  return (
                    <div className="w-100">{`Id: ${x.id} Log: ${x.log}`}</div>
                  );
                })*/}
              </div>
            </div>
            <div>
              {this.renderRecordings(this.state.recordings)}
            </div>
          </fieldset>
        </div>
      </>
    );
  }

  async populateRecordings(matchWithPatterns) {

    try {

      let matchWithPatternsParam = matchWithPatterns ? "?matchWithPatterns=true" : "";


      let url = `${Utils.getApiBaseUrl()}/recordings/${matchWithPatternsParam}`;

      if (matchWithPatterns) {
        let response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Content-Type': 'application/json'
          },
        });
      }

      let recordings = null;

      let promises = [];

      let promiseRecordings = fetch(url).then(r => {
        return r.json();
      }).then(x => {
        recordings = x;
      });
      promises.push(promiseRecordings);


      let patterns = null;

      let patternUrl = `${Utils.getApiBaseUrl()}/patterns`;

      let promisePatterns = fetch(patternUrl).then(r => {
        return r.json();
      }).then(x => {
        patterns = x;
      });

      promises.push(promisePatterns);
     // await promisePatterns;

      await Promise.all(promises);

      let patternIdsToPatterns = [];
      patterns.items.forEach(x => patternIdsToPatterns[x.id] = x);



      this.setState({
        recordings: recordings,
        patternIdsToPatterns: patternIdsToPatterns,
        loadingDialogText: null
      });
    }
    catch (e) {
      alert(`Failed loading recordings`);
    }


  }
}
