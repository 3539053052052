export class Utils {
  static strUtfDateTimeToLocalStrDate(strUtfDateTime) {
    if (strUtfDateTime != null && strUtfDateTime[strUtfDateTime.length - 1] != 'Z') {
      strUtfDateTime = strUtfDateTime + "Z";
    }
    let dateTime = new Date(strUtfDateTime);
    let strDate = `${dateTime.getFullYear()}-${(dateTime.getMonth() < 9) ? "0" : ""}${dateTime.getMonth() + 1}-${(dateTime.getDate() < 10) ? "0" : ""}${dateTime.getDate()}`;
    return strDate;
  }

  static strUtfDateTimeToLocalStrTime(strUtfDateTime) {
    if (strUtfDateTime != null && strUtfDateTime[strUtfDateTime.length - 1] != 'Z') {
      strUtfDateTime = strUtfDateTime + "Z";
    }
    let dateTime = new Date(strUtfDateTime);
    let strTime = `${(dateTime.getHours() < 10) ? "0" : ""}${dateTime.getHours()}:${dateTime.getMinutes() < 10 ? "0" : ""}${dateTime.getMinutes()}:${(dateTime.getSeconds() < 10) ? "0" : ""}${dateTime.getSeconds()}`;
    return strTime;
  }

  static getProjectUiName() {
    let name = window.location.hostname;

    name = name.toLowerCase();

    let foundDot = false;
    name = Array.from(name).filter((x) => {
      if (x == '.') {
        foundDot = true;
      }
      return !foundDot;
    });

    //uppercase zero index
    name[0] = name[0].toUpperCase();

    //back to string from char array
    name = name.join("");

    
    return name;
  }

  static getApiBaseUrl() {
    let name = window.location.hostname;

    //name = `/${name.toLowerCase()}/api`;
    name = `/api`;

    return name;
  }

}
