import React, { Component } from 'react';




export class RecordingRenderHelper {

  static renderUserAnnotations(recording) {
    return recording.annotations?.filter(x => x.user)?.map(x => {
      let userIconColorClass = (x.isMatch != null) ? (x.isMatch ? "text-success" : "text-danger") : "text-warning";
      return (
        <div>
          <i className={`fas fa-user ${userIconColorClass}`} ></i><span className="ml-1">{x.user.firstName + " " + (x.user.lastName ?? "")}</span>
          <div>{x.comment ? (<span className="w-10em">{x.comment}</span>) : null}</div>
        </div>
      )
    })
  }

  static renderDevice(recording) {
    return (
      <div className="w-6em">
        <i className={`fas fa-microphone text-success`} ></i><span className="ml-1">{recording?.device?.id}-{recording?.device?.name}</span>
      </div>
    );
  }
}
