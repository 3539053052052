import React, { Component } from 'react'
import { Utils } from './Utils';


export class RecordingPlayButton extends React.Component {

  btnStyle = null;


  constructor(props) {
    super(props);
    this.state = {
      play: false,
      url: "",

    };
    //makes handleAudioEnded pass this
    this.handleAudioEnded = this.handleAudioEnded.bind(this);
  }


  timerId = 0;

  audio = null;
  gotCanPlay = false;

  handleAudioEnded(){
    this.setState({ play: false });
  }

  componentWillUnmount() {
    if (this.audio != null) {
      this.audio.removeEventListener('ended', this.handleAudioEnded);
    }
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = 0;
    }
  }

  togglePlay = (e) => {
    this.props.onClick?.(e);

    let url = `${Utils.getApiBaseUrl()}/recordings/`
      + this.props.id + "/flake"
      + "?sampleFrom=" + (this.props.sampleFrom ?? "")
      + "&sampleCount=" + (this.props.sampleCount  ?? "")
      + "&minFrequency=" + (this.props.minFrequency ?? "")
      + "&maxFrequency=" + (this.props.maxFrequency ?? "")

      ;
    if (this.state.url != url)
    {
      //phones cant play wave so we ask for flac which api then converts wav -> flac
      if (this.audio) {
        this.audio.removeEventListener('ended', this.handleAudioEnded);
      }
      this.audio = new Audio(url);
      this.audio.addEventListener('ended', this.handleAudioEnded);
    }
    this.setState({
      play: !this.state.play,
      url: url,
    }, () => {
      if (this.state.play) {
        this.audio.play()
        console.log("play");
      }
      else {
        this.audio.pause();
      }
      if (this.state.play) {
        if (this.props.reportToSubscriber) {
          this.props.reportToSubscriber(this);
        }
        this.timerId = setInterval(() => {
          if (!this.state.play) {
            //stopping timer
            clearInterval(this.timerId);
            this.timerId = 0;
          }
          else {
            if (this.props.reportToSubscriber) {
              this.props.reportToSubscriber(this);
            }
          }
        }, 50);
      }
    });
  }

  render() {
    if (this.btnStyle == null) {
      this.btnStyle = (this.props.style != undefined) ? Object.assign({}, this.props.style) : {};
      this.btnStyle["width"] = "4em";
    }

    let btnClassName = this.props.className;
    return (
      <button value={this.props.value} className={btnClassName} onClick={this.togglePlay} style={this.btnStyle}>
        {this.state.play ? 'Pause' : 'Play'}
      </button>
    );
  }
}