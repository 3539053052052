import React, { Component } from 'react';
import { RecordingPlayButton } from './RecordingPlayButton';
import { Brush, LineChart, XAxis, YAxis, CartesianGrid, Line, ReferenceLine, ReferenceDot, Label, ReferenceArea, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Link } from "react-router-dom";
import { FrequencyChartMgr } from './FrequencyChartMgr';
import { UserMgr } from './UserMgr';
import { Utils } from './Utils';
import { LoadingDialog } from './LoadingDialog';


const ErrorType = {
  Error: 0,
  DiffedError: 1,
  DiffedWithIntensityError: 2,
}


const RecordingSampleRangeMatchesDot = (props) => {
  //todo make hash with fromSample
  let sampleRangeError = props.sampleRangeError;

  let match = false;
  let extraOffset = 0;

  if (props.errorType == ErrorType.DiffedWithIntensityError) {
    match = (sampleRangeError?.diffedMatchFaultsWithIntensity === 0);
  }
  if (props.errorType == ErrorType.DiffedError) {
    match = (sampleRangeError?.diffedMatchFaults === 0);
    extraOffset = 15;
  }

  if (match) {
    return (
      <svg x={props.cx - 10} y={0 + extraOffset} width={20} height={20} fill={props.stroke} viewBox="0 0 1024 1024" opacity="0.2">
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
      </svg>
    );
  }
  else {
    return <></>
  } 
};


export function SoundDataChartReferenceLabel(props, b,c) {
  let {
    fill, value, textAnchor,
    fontSize, viewBox, dy, dx, soundDataChartReferenceLabelInfo, offset
  } = props;

  let emSize = 10;
  if (soundDataChartReferenceLabelInfo) {
    let noOfChars = 21;
    if (soundDataChartReferenceLabelInfo.sampleNo > soundDataChartReferenceLabelInfo.midSampleNo) {
      offset = - (offset + (noOfChars * emSize * 0.7));
    }
  }
  const x = viewBox.width + viewBox.x + offset;
  const y = viewBox.y - 6;
  return (
    <text fontSize={emSize}
      x={x} y={y}
      dy={dy}
      dx={dx}
      fill={fill}
      textAnchor={textAnchor}>
      <tspan x={x} dy="1.2em">Sample: {soundDataChartReferenceLabelInfo?.sampleNo} - {(soundDataChartReferenceLabelInfo?.nextSampleNo ?? 1) - 1}</tspan>
      <tspan x={x} dy="1.2em">Time:  {soundDataChartReferenceLabelInfo?.time.toFixed(3)} - {soundDataChartReferenceLabelInfo?.nextTime.toFixed(3)} s</tspan>
      <tspan x={x} dy="1.2em">Max:   {soundDataChartReferenceLabelInfo?.maxValue}</tspan>
      <tspan x={x} dy="1.2em">Min:   {soundDataChartReferenceLabelInfo?.minValue}</tspan>
    </text>
  )
}



class AmplitudeChartDataSet {
  graphData = null;
  recording = null;
  xMiddle = 0;
  sampleFrom = 0;
  sampleCount = 0;
  yMax = 0;
  yMin = 0;
  minFrequency = 0;
  maxFrequency = 0;

  constructor(recording, minFrequency, maxFrequency) {
    let itemsBandpassed = recording.soundData.itemsBandpassed ?? recording.soundData.items;

    this.graphData = recording.soundData.items.map((soundDataItem, i) => {
      let graphDataItem = {
        "x": soundDataItem.x,
        "xNext": soundDataItem.xNext,
        "yMinBp": itemsBandpassed[i].yMin,
        "yMaxBp": itemsBandpassed[i].yMax,
        "yMin": soundDataItem.yMin,
        "yMax": soundDataItem.yMax,
      };
      return graphDataItem;
    });

    if (this.graphData.length > 0) {
      this.xMiddle = this.graphData[Math.floor(recording.soundData.items.length / 2)].x;
      this.sampleCount = recording.soundData.sampleCount;
      this.sampleFrom = recording.soundData.sampleFrom;
    }
    else {
      this.xMiddle = 0;
      this.sampleFrom = 0;
      this.sampleCount = 0;
    }
    this.yMax = recording.soundData.yMax;
    this.yMin = recording.soundData.yMin;
    this.minFrequency = minFrequency;
    this.maxFrequency = maxFrequency;
  }
}

export class RecordingPage extends Component {
  static displayName = RecordingPage.name;
  user = new UserMgr().getCurrentUser();

  amplitudeChartDataSets = [];
  //set when we receive recording
  sampleRate = 0;
  recording = null;

  constructor(props) {
    super(props);

    let minFrequency = 0;
    let maxFrequency = 0x7FFFFFFF;


    this.state = {
      patternId: null,
      loading: true,
      soundDataChartReferenceLinePosition: 0,
      sampleFromWanted: null,
      sampleCountWanted: null,
      minFrequency: this.user.settings.recording.amplitudeOverTime.minHertz,
      strMinFrequency: "" + this.user.settings.recording.amplitudeOverTime.minHertz,
      maxFrequency: maxFrequency,
      strMaxFrequency: "" + maxFrequency,
      sampleRangesChart_info: null,
      recordingChartShowUnfiltered: false,
      soundDataChartReferenceLabelInfo: null,
    };

  }

  getUriParamsLc(props) {
    //'?patternId=katt2'
    let nameValuesLc = (props.location.search[0] == "?") ? props.location.search.substring(1).split("&").map(x => {
      let strsNameValue = x?.split('=');


      let nameValue = {
        name: strsNameValue[0]?.toLowerCase(),
        value: strsNameValue[1]?.toLowerCase(),
      };

      return nameValue;
    }).filter(x => x.name != null) : []

    return nameValuesLc;
  }

  nextId = null;
  prevId = null


  componentDidMount() {
    let patternId = this.getUriParamsLc(this.props).find(x => x.name == "patternid")?.value;
    this.nextId = null;
    this.prevId = null;

    this.amplitudeChartDataSets = [];
    this.populateRecording(0, null, true, this.state.minFrequency, this.state.maxFrequency, patternId);
  }

  componentDidUpdate(prevProps, prevState) {
    let prev_patternId = this.getUriParamsLc(prevProps).find(x => x.name == "patternid")?.value;
    let patternId = this.getUriParamsLc(this.props).find(x => x.name == "patternid")?.value;

    let prev_idOffset = this.getUriParamsLc(prevProps).find(x => x.name == "idoffset")?.value;
    let idOffset = this.getUriParamsLc(this.props).find(x => x.name == "idoffset")?.value;


    if (prevProps.match.params.id != this.props.match.params.id
      || (prev_patternId != patternId)
      || (prev_idOffset != idOffset)
    ) {
      this.componentDidMount();
    }
  }




  binarySearch(sortedArray, valueToFind, getValueFunction) {
    let start = 0;
    let end = sortedArray.length - 1;

    let middleIndex = Math.floor((start + end) / 2);

    while (start < end) {
      middleIndex = Math.floor((start + end) / 2);
      let middleIndexValue = getValueFunction(sortedArray[middleIndex]);

      if (middleIndexValue === valueToFind) {
        // found the key
        return middleIndex;
      } else if (middleIndexValue < valueToFind) {
        // continue searching to the right
        start = middleIndex + 1;
      } else {
        // search searching to the left
        end = middleIndex - 1;
      }
    }
    if (start == end) {
      middleIndex = start;
    }
    // key wasn't found
    return middleIndex;
  }

  findClosestSoundDataGraphData(sampleNo) {
    //TODO: optimize this
    let graphData = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1]?.graphData ?? [];
    let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];

    let soundDataGraphDataItemIndex = this.binarySearch(graphData, sampleNo, (x) => { return x.x; });
    let soundDataGraphDataItem = graphData[soundDataGraphDataItemIndex];
    return soundDataGraphDataItem;
  }

  audio = null;

  sampleToFlacTime(sample) {
    let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];

    let currentTime = ((sample - amplitudeChartDataSet.sampleFrom)  * 1.0)  / this.sampleRate;
    return currentTime;
  }


  RecordingPlayButton_reportToSubscriber = (recordingPlayButton) => {
    this.audio = recordingPlayButton.audio;
    if (!(this.audio?.paused ?? true)) {
      let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];
      let sample = (amplitudeChartDataSet?.sampleFrom ?? 0) + ((this.audio?.currentTime ?? 0) * this.sampleRate);

      let soundDataGraphDataItem = this.findClosestSoundDataGraphData(sample);
      let soundDataChartReferenceLinePosition = soundDataGraphDataItem?.x ?? this.state.soundDataChartReferenceLinePosition;


      this.setState({
        soundDataChartReferenceLinePosition: soundDataChartReferenceLinePosition
      });
    }
  }


  renderSoundDataChartReferenceLabelInfo = (x) => {
    if (this.recording) {
      let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];
      let graphData = amplitudeChartDataSet?.graphData;
      let midSampleNo = amplitudeChartDataSet?.xMiddle ?? 0;
      let closestSoundDataGraphData = this.findClosestSoundDataGraphData(x);

      let secsPerSample = 1.0 / this.recording.sampleRate ?? 1;
      let nextSampleNo = closestSoundDataGraphData?.xNext ?? 0;

      let soundDataChartReferenceLabelInfo = {
        sampleNo: closestSoundDataGraphData?.x ?? 0,
        nextSampleNo: nextSampleNo,
        time: secsPerSample * x,
        nextTime: secsPerSample * nextSampleNo,
        maxValue: closestSoundDataGraphData?.yMax ?? 0,
        minValue: closestSoundDataGraphData?.yMin ?? 0,
        midSampleNo: midSampleNo,
      }
      this.setState({ soundDataChartReferenceLabelInfo: soundDataChartReferenceLabelInfo });
    }
  }

  zoom = (calledFromUpperGraph) => {
    let { sampleFromWanted, sampleCountWanted } = this.state;
    const { data } = this.state;

    if ((sampleCountWanted ?? 0) < 4) {
      this.setState(() => ({
        sampleFromWanted: null,
        sampleCountWanted: null
      }));
      return;
    }

    if (sampleCountWanted < 0) {
      // swap
      sampleFromWanted = sampleFromWanted - sampleCountWanted - 1;
      sampleCountWanted = -sampleCountWanted;
    };


    this.setState(() => ({
      sampleFromWanted: null,
      sampleCountWanted: null,
    }));

    let push = false;
    if (calledFromUpperGraph) {
      push = true;
    }
    else {
      let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];
      if ((sampleFromWanted >= amplitudeChartDataSet.sampleFrom)
        && sampleFromWanted + sampleCountWanted <= amplitudeChartDataSet.sampleCount) {
        push = true;
      }
    }


    this.populateRecording(Math.floor(sampleFromWanted), Math.floor(sampleCountWanted), push, this.state.minFrequency, this.state.maxFrequency);
  }

  zoomOut = (e) => {
    if (this.amplitudeChartDataSets.length > 1) {
      this.amplitudeChartDataSets.pop();

      let soundGrapDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];


      //trigger rerender
      this.state.loading = true;

      this.setState({
        loading: false,
        minFrequency : soundGrapDataSet.minFrequency,
        strMinFrequency : "" + soundGrapDataSet.minFrequency,
        maxFrequency : soundGrapDataSet.maxFrequency,
        strMaxFrequency : "" + soundGrapDataSet.maxFrequency,
      });
      this.renderSoundDataChartReferenceLabelInfo(this.state.soundDataChartReferenceLinePosition);
    }
  }




  handleLineChartMouseDown = (e) => {
    if (e != null && e.activeLabel != null) {
      this.setState({ sampleFromWanted: e.activeLabel });
    }
  }

  handleLineChartMouseMove = (e) => {
    if (this.state.sampleFromWanted) {
      this.setState({
        sampleCountWanted: (e != null) ? (e.activeLabel - this.state.sampleFromWanted - 1) : this.state.sampleFromWanted
      });
    }
  }

  handleLowerLineChartClick = (e) => {
    this.handleLineChartClick(e, true);
  }


  handleLineChartClick = (e, isLower) => {
    if (e != null && e.activeLabel != null) {
      if (!(this.audio?.paused ?? true)) {
        this.audio.currentTime = this.sampleToFlacTime(e.activeLabel);
      }
      else {
        this.setState({ soundDataChartReferenceLinePosition: e.activeLabel });
        if (this.amplitudeChartDataSets.length > 1) {
          let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];
          let sampleUntilCoveredInSoundData = amplitudeChartDataSet.sampleFrom + amplitudeChartDataSet.sampleCount - 1;
          if ((isLower === true) ||  (e.activeLabel < amplitudeChartDataSet.sampleFrom || e.activeLabel > sampleUntilCoveredInSoundData)) {
            let sampleFrom = Math.floor(Math.max(0, e.activeLabel - (amplitudeChartDataSet.sampleCount / 2)));
            this.state.loading = true;
            this.populateRecording(sampleFrom, amplitudeChartDataSet.sampleCount, false, this.state.minFrequency, this.state.maxFrequency);

          }
        }
      }
      this.renderSoundDataChartReferenceLabelInfo(e.activeLabel);
    }
  }


  handleLineChartZoomInClick = (e) => {
    let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];
    var newRange = amplitudeChartDataSet.sampleCount / 4;
    let sampleFrom = parseInt(Math.max(0, this.state.soundDataChartReferenceLinePosition - (newRange / 2)));
    let dataUntil = parseInt(Math.min(this.state.soundDataChartReferenceLinePosition + amplitudeChartDataSet.sampleCount - 1, this.state.soundDataChartReferenceLinePosition + (newRange / 2)));
    let sampleCount = dataUntil - sampleFrom;
    this.state.loading = true;
    this.populateRecording(sampleFrom, sampleCount, true, this.state.minFrequency, this.state.maxFrequency);
    this.renderSoundDataChartReferenceLabelInfo(this.state.soundDataChartReferenceLinePosition);
    console.log('The link was clicked.');
  }

  handleSampleRangesChartClick = (sampleRange) => {
    let e = {
      activeLabel: sampleRange.fromSample
    };
    this.handleLineChartClick(e);
  }


  handleZoomFromLowerGraph = () => {
    this.zoom(false);
  }

  handleZoomFromUpperGraph = () => {
    this.zoom(true);
  }

  minFrequencyChange = (e) => {
    let strValue = e.target.value;
    this.setState({
      strMinFrequency : strValue,
    })
  }

  maxFrequencyChange = (e) => {
    let strValue = e.target.value;
    this.setState({
      strMaxFrequency: strValue,
    })
  }

  bandpassUpdateClicked = () => {
    if (!(isNaN(parseInt(this.state.strMinFrequency)) || isNaN(parseInt(this.state.strMaxFrequency)))) {
      let minFrequency = parseInt(this.state.strMinFrequency);
      let maxFequency = parseInt(this.state.strMaxFrequency);
      this.setState({
        minFrequency: minFrequency,
        strMinFrequency: "" + minFrequency,
        maxFrequency: maxFequency,
        strMaxFrequency: "" + maxFequency,
      })
      this.state.loading = true;
      //this.amplitudeChartDataSets = [];
      let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1];
      this.populateRecording(amplitudeChartDataSet.sampleFrom, amplitudeChartDataSet.sampleCount, false, minFrequency, maxFequency);
    }
  }

  in_handleFrequencyChartMgrActiveRangeChange = false;

  handleSampleRangesChartActiveSampleRangeChange = (info) => {
    if (!this.in_handleFrequencyChartMgrActiveRangeChange) {
      if (this.state.sampleRangesChart_info?.sampleRange?.fromSample != info?.sampleRange?.fromSample || this.state.sampleRangesChart_info?.pattern != info?.pattern ) {
        this.in_handleFrequencyChartMgrActiveRangeChange = true;
        this.setState({
          sampleRangesChart_info: info,
        }, () => this.in_handleFrequencyChartMgrActiveRangeChange = false)
      }
    }
  }




  handleChkShowUnfiltered = (e) => {
    let recordingChartShowUnfiltered = e?.target?.checked;
    this.setState({
      recordingChartShowUnfiltered: recordingChartShowUnfiltered,
    });
  }

  handleClickDelete = async (e) => {

    if (!window.confirm(`Do you really want to delete recording: ${this.recording.id}`)) {
      return;
    }


    let deleteLog = {};
    try {
      var url = `${Utils.getApiBaseUrl()}/recordings/${this.recording.id}`;
      deleteLog = await fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json());
    }
    catch (e) {
      //TODO: should return the deleteLog from the server if they exist
      deleteLog = {
        items: [{ 
          id: this.recording.id,
          log: `RecordingsPage failed deleting recording ${this.recording.id}\r\n${e.message}`
        }]
      };
    }
    let strDeleteLogs = deleteLog.items?.map((x) => {
      return `Id: ${x.id} Log: ${x.log}`;
    }).join("\r\n");
    if (strDeleteLogs?.length) {
      alert(strDeleteLogs);
    }

    let search = this.props.location.search;
    let goto = (this.recording.id != this.prevId) ? `/recording/${this.prevId}${search}` : `/recording/${this.nextId}${search}`;
    this.props.history.replace(goto);
  }


  renderPatternSourcesSampleRanges = () => {
    let patternSampleRanges = this.state.sampleRangesChart_info?.pattern?.sources.find(x => x.recording.id == this.recording?.id)?.sampleRanges ?? [];
    let outerRetval = patternSampleRanges.map((x) => {
      let retval = (
        <>
          <ReferenceArea x1={x.usedFromSample} x2={x.usedFromSample + this.state.sampleRangesChart_info?.samplesPerWindow} stroke="#F00" fillOpacity={0.2} strokeOpacity={0.5} />
          <ReferenceLine type="number" x={x.fromSample} stroke="#F00" strokeDasharray="3 3" strokeOpacity={0.5} />
        </>
      );
      return retval;
    });
    return outerRetval;
  }

  renderPatternMatches = () => {
    let sampleRangeErrors = this.state.pattern?.matchResult?.sampleRangeErrors ?? []
    let outerRetval = sampleRangeErrors
      .filter(x => x.diffedMatchFaultsWithIntensity === 0 || x.diffedMatchFaults === 0)
      .map((x) => {
        let retval = (
          <>
            <ReferenceDot x={x.fromSample} y={10} stroke="darkorange" fillOpacity={0.2} strokeOpacity={0.5} shape={<RecordingSampleRangeMatchesDot errorType={ErrorType.DiffedError} sampleRangeError={x} />} />
            <ReferenceDot x={x.fromSample} y={20} stroke="blue" fillOpacity={0.2} strokeOpacity={0.5} shape={<RecordingSampleRangeMatchesDot errorType={ErrorType.DiffedWithIntensityError} sampleRangeError={x} />} />
          </>
        );
        return retval;
      });
    return outerRetval;
  }



  renderRecording() {
    let amplitudeChartDataSet = this.amplitudeChartDataSets.length ? this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1] : null;
    let disableUpdateFilter = ("" + this.state.minFrequency) == this.state.strMinFrequency && ("" + this.state.maxFrequency) == this.state.strMaxFrequency;


    let nextUri = `/recording/${encodeURI(this.nextId)}${ this.props.location.search }`;
    let prevUri = `/recording/${encodeURI(this.prevId)}${this.props.location.search}`;


    let retval = (
      <div>
        <div>
          {/*<span className="mr-1">*/}
          <Link className="btn  btn-primary mr-1 " to={prevUri} style={{ "width": "8em" }}>Prev ({this.prevId})</Link>
          <Link className="btn  btn-primary mr-1" to={nextUri} style={{ "width": "8em" }}>Next ({this.nextId})</Link>
          
          {/*</span>*/}
          <label className="mr-1" >Date:</label>
          <span className="mr-2 font-weight-bold" >{Utils.strUtfDateTimeToLocalStrDate(this.recording?.dateCreatedUtc)}</span>
          <label className="mr-1" >Time:</label>
          <span className="mr-2 font-weight-bold" >{Utils.strUtfDateTimeToLocalStrTime(this.recording?.dateCreatedUtc)}</span>

          <label className="mr-1" >Info:</label>
          <span className="mr-2 font-weight-bold" >{`SR: ${this.recording?.orgSampleRate} -> ${this.recording?.sampleRate} SPS: ${this.recording?.samplesPerSample} BPS: ${this.recording?.bytesPerSample} Unit: ${this.recording?.device?.id}-${this.recording?.device?.name}`}</span>
        </div>
        <fieldset className="border rounded border-primary p-1">
          <legend className="h6 ml-1 w-16em">Amplitude over time</legend>
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-auto mt-1">
                <RecordingPlayButton id={this.recording?.id ?? null} className="btn  btn-primary"
                  reportToSubscriber={this.RecordingPlayButton_reportToSubscriber}
                  sampleFrom={amplitudeChartDataSet?.sampleFrom ?? 0} sampleCount={amplitudeChartDataSet?.sampleCount ?? 0}
                  minFrequency={this.state.minFrequency} maxFrequency={this.state.maxFrequency}
                >
                  Play
                </RecordingPlayButton>
              </div>
              <div className="col-auto mt-1">
              <button className="btn  btn-primary mr-1" onClick={this.handleClickDelete}>Delete</button>
              </div>

              <div className="col-auto mt-1">
                <button style={{width: "8em" }} disabled={this.amplitudeChartDataSets.length < 2} onClick={this.zoomOut} className="btn btn-primary mr-1" >
                  Zoom out ({this.amplitudeChartDataSets.length})
                </button>
                <button style={{ width: "8em" }} onClick={this.handleLineChartZoomInClick} className="btn btn-primary" >
                  Zoom in
                </button>

              </div>
              <div className="col-auto mt-1 form-group">
                <label className="mr-1" >Min Hz:</label>
                <input className="input border mr-1" style={{ width: "4em" }} type="text" value={this.state.strMinFrequency} onChange={this.minFrequencyChange}></input>
                <label className="mr-1" >Max Hz:</label>
                <input className="input border mr-1" style={{ width: "4em" }} type="text" value={this.state.strMaxFrequency} onChange={this.maxFrequencyChange}></input>
                <button disabled={disableUpdateFilter} onClick={this.bandpassUpdateClicked} className="btn btn-primary" >
                  Update
                </button>
                <input className="btn btn-primary recordingChecked ml-1 mr-1" type="checkbox" value="" id="recordingChecked" onClick={this.handleChkShowUnfiltered} />
                <label htmlFor="recordingChecked">Show unf.</label>

              </div>
            </div>
          </div>

          <ResponsiveContainer width="100%" height={200}>
            <LineChart width={400} height={300} data={amplitudeChartDataSet?.graphData} 
              onMouseDown={this.handleLineChartMouseDown}
              onMouseMove={this.handleLineChartMouseMove}
              onMouseUp={this.handleZoomFromUpperGraph}
              onClick={this.handleLineChartClick}
              id="chartSampleRange"
              className="chartSampleRange"
            >
              <XAxis domain={[amplitudeChartDataSet?.sampleFrom ?? 0, (amplitudeChartDataSet?.sampleFrom ?? 0) + (amplitudeChartDataSet?.sampleCount ?? 1) - 1]} dataKey="x" type="number" />
              <YAxis type="number" />
              <ReferenceLine type="number" y="0" stroke="#ccc" />
              <Line isAnimationActive={false} type=" linear" dataKey={this.state.recordingChartShowUnfiltered ? "yMin" : null} stroke="#8884d8" dot={false} />
              <Line isAnimationActive={false} type=" linear" dataKey={this.state.recordingChartShowUnfiltered ? "yMax" : null} stroke="#8884d8" dot={false} />
              <Line isAnimationActive={false} type=" linear" dataKey="yMinBp" stroke="#f88" dot={false} />
              <Line isAnimationActive={false} type=" linear" dataKey="yMaxBp" stroke="#f88" dot={false} />

              {/*set type=number then it works between numbers*/}

              <ReferenceLine type="number" x={this.state.soundDataChartReferenceLinePosition} stroke="black" 
                label={<SoundDataChartReferenceLabel soundDataChartReferenceLabelInfo={this.state.soundDataChartReferenceLabelInfo} />}
              />
              <ReferenceLine type="number" x={this.state.soundDataChartReferenceLinePosition + this.state?.sampleRangesChart_info?.samplesPerWindow} stroke="black" strokeDasharray="3 3"
              />

              <ReferenceLine type="number" x={this.state.sampleRangesChart_info?.sampleRange?.fromSample} stroke="red" />
              <ReferenceLine type="number" x={this.state.sampleRangesChart_info?.sampleRange?.fromSample + this.state.sampleRangesChart_info?.samplesPerWindow} stroke="red" />
              <ReferenceArea x1={this.state.sampleFromWanted ?? 0} x2={(this.state.sampleFromWanted ?? 0) + (this.state.sampleCountWanted ?? 0)} label={this.state.sampleCountWanted } stroke="#F00" strokeOpacity={0.3} />
              {this.renderPatternMatches()}
              {this.renderPatternSourcesSampleRanges()}
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height={100}>
            <LineChart width={400} height={0} data={this.amplitudeChartDataSets[0]?.graphData ?? 0}
              onMouseDown={this.handleLineChartMouseDown}
              onMouseMove={this.handleLineChartMouseMove}
              onMouseUp={this.handleZoomFromLowerGraph}
              onClick={this.handleLowerLineChartClick}

            >
              <XAxis domain={[this.amplitudeChartDataSets[0]?.sampleFrom ?? 0, (this.amplitudeChartDataSets[0]?.sampleCount ?? 1) - 1]} dataKey="x" type="number" />
              <YAxis domain={[this.amplitudeChartDataSets[0]?.yMin ?? 0, this.amplitudeChartDataSets[0]?.yMax ?? 0]} type="number" />
              <Line isAnimationActive={false} type="linear" dataKey="yMin" stroke="#8884d8" dot={false} />
              <Line isAnimationActive={false} type="linear" dataKey="yMax" stroke="#8884d8" dot={false} />
              <ReferenceArea x1={amplitudeChartDataSet?.sampleFrom ?? 0} x2={(amplitudeChartDataSet?.sampleFrom ?? 0) + (amplitudeChartDataSet?.sampleCount ?? 1) - 1} label={amplitudeChartDataSet?.sampleCount ?? 1} stroke="#F00" strokeOpacity={0.9} />
              <ReferenceArea x1={this.state.sampleFromWanted ?? 0} x2={(this.state.sampleFromWanted ?? 0) + (this.state.sampleCountWanted ?? 1) - 1} label={this.state.sampleCountWanted} stroke="#F00" strokeOpacity={0.3} />
              <ReferenceLine type="number" x={this.state.soundDataChartReferenceLinePosition} stroke="gray" />
              <ReferenceLine type="number" x={this.state.sampleRangesChart_info?.sampleRange?.fromSample} stroke="red" />
              <ReferenceLine type="number" x={this.state.sampleRangesChart_info?.sampleRange?.fromSample + this.state.sampleRangesChart_info?.samplesPerWindow} stroke="red" />
              {this.renderPatternMatches()}
              {this.renderPatternSourcesSampleRanges()}
            </LineChart>
          </ResponsiveContainer>

        </fieldset>
      </div>
    );
    return retval;
  }


  render() {

    //rendr the title
    let comment = this.recording?.annotations?.find(x => x.comment)?.comment;
    let title = `${Utils.getProjectUiName()} - ${this.recording?.id} ${comment ? " - " : ""} ${comment ?? ""}`;
    document.title = title;

    let amplitudeChartDataSet = this.amplitudeChartDataSets[this.amplitudeChartDataSets?.length - 1];
    return (
      <div className="recordingPageContainer" data-id={this.recording?.id}>
        <h4>{title}</h4>
        <LoadingDialog show={this.state.loading}/>
        {this.renderRecording()}
        <FrequencyChartMgr recordingId={this.props.match.params.id} patternId={this.state.patternId} pattern={this.state.pattern} soundDataChartReferenceLinePosition={this.state.soundDataChartReferenceLinePosition}
          onSampleRangesChartClick={this.handleSampleRangesChartClick} onSampleRangesChartActiveSampleRangeChange={this.handleSampleRangesChartActiveSampleRangeChange} sampleFrom={amplitudeChartDataSet?.sampleFrom} sampleCount={amplitudeChartDataSet?.sampleCount}
        />
      </div>
    );
  }

  async populateRecording(sampleFrom, sampleCount, push, minFrequency, maxFrequency, patternId) {

    try {

      let idOffsetValue = this.getUriParamsLc(this.props).find(x => x.name == "idoffset")?.value;

      let promises = [];

      this.setState({
        loading: true,
      });


      if (this.nextId == null) {
        let uri = `${Utils.getApiBaseUrl()}/recordings/${encodeURI(this.props.match.params.id)}?idOffset=1`;

        let promise = fetch(uri).then(response => response.json()).then(recording => {
          this.nextId = recording.id;
        });
        promises.push(promise);
      }
      if (this.prevId == null) {
        let uri = `${Utils.getApiBaseUrl()}/recordings/${encodeURI(this.props.match.params.id)}?idOffset=-1`;

        let promise = fetch(uri).then(response => response.json()).then(recording => {
          this.prevId = recording.id;
        });
        promises.push(promise);
      }

      let pattern = null;
      if (patternId)
      {
        let url = `${Utils.getApiBaseUrl()}/patterns/${patternId}?recordingId=${encodeURI(this.props.match.params.id)}`;

        let promise = await fetch(url, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        })
          .then(response => response.json())
          .then(x => {
            pattern = x;
            if (pattern?.matchResult?.sampleRangeErrors) { //create a hash fromSample -> result
              let fromSamplesToSampleRangeErrors = [];
              pattern?.matchResult.sampleRangeErrors?.forEach(x => fromSamplesToSampleRangeErrors[x.fromSample] = x);
              pattern.matchResult["fromSamplesToSampleRangeErrors"] = fromSamplesToSampleRangeErrors;
            }
          });
        promises.push(promise);
      }
      else
      {
        pattern = this.state.pattern;
      }


      let recording = null;

      {
        let strDataUntil = (sampleCount != null) ? ("&sampleCount=" + sampleCount) : "";
        let uri = `${Utils.getApiBaseUrl()}/recordings/`
          + encodeURI(this.props.match.params.id)
          + "?sampleFrom=" + sampleFrom
          + "&dataCount=400"
          + strDataUntil
          + "&minFrequency=" + minFrequency
          + "&maxFrequency=" + maxFrequency
          ;

        let promise = fetch(uri).then(response => response.json()).then(x => {
          recording = x;
        });
        promises.push(promise);
      }


      await Promise.all(promises);



      this.recording = recording;
      //(index % 2) ? value.yMax : value.yMin so we don miss the dynamic of the graph.


      let amplitudeChartDataSet = new AmplitudeChartDataSet(this.recording, minFrequency, maxFrequency);
      if (sampleFrom <= amplitudeChartDataSet.sampleFrom && sampleCount > amplitudeChartDataSet.sampleCount) {
        push = true;
      }


      if (push) {
        this.amplitudeChartDataSets.push(amplitudeChartDataSet);
      }
      else {
        this.amplitudeChartDataSets[this.amplitudeChartDataSets.length - 1] = amplitudeChartDataSet;
      }
      this.sampleRate = recording.sampleRate;

      if (maxFrequency == 0x7FFFFFFF) {
        //happens first time
        maxFrequency = this.recording.sampleRate / 2;

        this.setState({
          maxFrequency: maxFrequency,
          strMaxFrequency: "" + maxFrequency,
        });
        amplitudeChartDataSet.maxFrequency = maxFrequency;
      }

      this.setState({
        patternId: patternId,
        pattern: pattern,
      });

    }
    finally {
      this.setState({
        loading: false,
      });
    }




  }
}
