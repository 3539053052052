export class UserMgr {

  user = {
    id: 1,
    firstName: "henrik",
    settings: {
      recording: {
        amplitudeOverTime: {
          minHertz: 0,
        },
        frequenciesOverTime: {
          minHertz: 0,
        },
        sampleRangeFrequencyChart: {
          showAmplFromSampleRange: false,
        }
      }
    }
  };

  getCurrentUser() {
    //TODO: should fetch this from users
    return this.user;
  }
}
