import React, { Component, PureComponent } from 'react';

export class FrequencySelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lowerFrequency: props.lowerFrequency,
      strLowerFrequencyWanted: null,

      upperFrequency: props.upperFrequency,
      strUpperFrequencyWanted: null,
    };
  }


  /*
  static getDerivedStateFromProps(props, state) {
    //change state according to new props
    return {
      strLowerFrequencyWanted: (state.strLowerFrequencyWanted != null) ? state.strLowerFrequencyWanted : `${props.lowerFrequency}`,
      strUpperFrequencyWanted: (state.strUpperFrequencyWanted != null) ? state.strUpperFrequencyWanted : `${props.upperFrequency}`,
    };
  }
  */

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;
    if (nextProps.lowerFrequency != this.props?.lowerFrequency
      || nextProps.upperFrequency != this.props?.upperFrequency) {
      nextState.strLowerFrequencyWanted = `${nextProps.lowerFrequency}`;
      nextState.strUpperFrequencyWanted = `${nextProps.upperFrequency}`;
      shouldUpdate = true;
    };

    shouldUpdate |= nextState.strLowerFrequencyWanted != this.state.strLowerFrequencyWanted;
    shouldUpdate |= nextState.strUpperFrequencyWanted != this.state.strUpperFrequencyWanted;

    return shouldUpdate;
  }



  handleLowerFreqChange = (e) => {
    this.setState({
      strLowerFrequencyWanted: e.target.value
    });
  }

  handleUpperFreqChange = (e) => {
    this.setState({
      strUpperFrequencyWanted: e.target.value
    });
  }

  fromWantedToState = async (maxFrequency) => {
    let strLowerFrequencyWanted = (parseInt(this.state.strLowerFrequencyWanted) == NaN) ? ("" + this.state.lowerFrequency) : this.state.strLowerFrequencyWanted;
    let strUpperFrequencyWanted = (parseInt(this.state.strUpperFrequencyWanted) == NaN) ? ("" + this.state.upperFrequency) : this.state.strUpperFrequencyWanted;

    let lowerFrequency = parseInt(strLowerFrequencyWanted);
    if (lowerFrequency < 0) {
      lowerFrequency = 0;
    }

    let upperFrequency = parseInt(strUpperFrequencyWanted);
    if ((upperFrequency < lowerFrequency) || upperFrequency > maxFrequency) {
      upperFrequency = maxFrequency;
    }

    await this.setState({
      lowerFrequency: lowerFrequency,
      upperFrequency: upperFrequency,
    });

    return this.state;
  }



  render() {
    let retval = (
      <>
        <span className="w-100">
          <label style={{ marginRight: "0.2em" }}>Range (Hz):</label>
          <input id="lowerFrequency" style={{ "width": "4.5em", marginRight: "0.2em" }} className="" type="text" value={this.state.strLowerFrequencyWanted ?? ""} onChange={this.handleLowerFreqChange} />
        </span>
        <span className="w-100">
          <label style={{ marginRight: "0.2em" }}>-</label>
          <input id="upperFrequency" style={{ "width": "4.5em", marginRight: "0.2em" }} className="" type="text" value={this.state.strUpperFrequencyWanted ?? ""} onChange={this.handleUpperFreqChange} />
        </span>
      </>

    );
    return retval;

  }
}