import React, { Component } from 'react';
import { Utils } from './Utils';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>{Utils.getProjectUiName()}</h1>
        <p>Welcome to the {Utils.getProjectUiName()} site:</p>
      </div>
    );
  }
}
