import React, { Component } from 'react';


export class LoadingDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show: true
    };
  }

  handleCloseBtn= () => {
    this.setState = {
      show: false,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState = {
      show: true,
    };
  }

  render() {
    let displayStyle = (this.state.show && this.props.show) ? "block" : "none"

    return (
      <>
        <div className="modal" id="dlgLoading" tabIndex="-1" style={{ display: displayStyle }} >
          {/*<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">*/}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Loading data</h5>
                <button type="button" className="close" onClick={this.handleCloseBtn} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.props.message ?? "Please with while loading data..."}
              </div>
              {/*
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
              */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
