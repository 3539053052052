import React, { Component } from 'react';
import { XAxis, YAxis, ResponsiveContainer, ReferenceArea, ReferenceLine, LineChart, Line, Legend } from 'recharts';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label as ReactStrapLabel } from 'reactstrap';
import { UserMgr } from './UserMgr';
import { FrequencySelection } from './FrequencySelection';
import { Utils } from './Utils';



const ErrorType = {
  Error: 0,
  DiffedError: 1,
  DiffedWithIntensityError: 2,
}


const SampleRangeMatchesDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;
  //todo make hash with fromSample
  let sampleRangeError = props.thisPtr.state.pattern?.matchResult.fromSamplesToSampleRangeErrors?.[props.payload?.fromSample];

  let match = false;
  let extraOffset = 0;

  if (props.errorType == ErrorType.DiffedWithIntensityError) {
    match = (sampleRangeError?.diffedMatchFaultsWithIntensity === 0);
  }
  if (props.errorType == ErrorType.DiffedError) {
    match = (sampleRangeError?.diffedMatchFaults === 0);
    extraOffset = 15;
  }



  if (match) {
    return (
      <svg x={cx - 10} y={props.height + extraOffset - 50} width={20} height={20} fill={props.stroke} viewBox="0 0 1024 1024" opacity="0.2">
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
      </svg>
    );
  }
  else {
    /*
    return (
      <svg x={cx - 10} y={cy - 10} width={10} height={10} fill="red" viewBox="0 0 1024 1024">
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
      </svg>
    );
    */
    return null;// (<svg></svg>);
  }
};

export function FrequenciesChartBarReferenceLabel(props) {
  const {
    fill, value, textAnchor,
    fontSize, viewBox, dy, dx, rangeFrequenciesChartBarReferenceLineInfo
  } = props;
  const x = viewBox.width + viewBox.x + 20;
  const y = viewBox.y - 6;
  let nextFromSample = rangeFrequenciesChartBarReferenceLineInfo.fromSample + rangeFrequenciesChartBarReferenceLineInfo.samplesPerWindow;
  return (
    <text
      x={x} y={y}
      dy={dy}
      dx={dx}
      fill={fill}
      fontSize={fontSize || 10}
      textAnchor={textAnchor}>
      <tspan x={x} dy="4em">{`Samples: ${rangeFrequenciesChartBarReferenceLineInfo.fromSample} - ${nextFromSample} `}</tspan>
      <tspan x={x} dy="1.2em">{`From Freq: ${rangeFrequenciesChartBarReferenceLineInfo.frequency} Hz`}</tspan>
      <tspan x={x} dy="1.2em">{`Amplitude: ${rangeFrequenciesChartBarReferenceLineInfo.ampl}`}</tspan>
      <tspan x={x} dy="1.2em">{`Before Amplitude: ${rangeFrequenciesChartBarReferenceLineInfo.beforeAmpl}`}</tspan>
      <tspan x={x} dy="1.2em">{`Diffed Amplitude: ${rangeFrequenciesChartBarReferenceLineInfo.diffedAmpl}`}</tspan>
      <tspan x={x} dy="1.2em">{`Ampl.N.: ${rangeFrequenciesChartBarReferenceLineInfo.amplNormalized.toFixed(3)}`}</tspan>
      <tspan x={x} dy="1.2em">{`Diffed Ampl.N.: ${rangeFrequenciesChartBarReferenceLineInfo.diffedAmplNormalized.toFixed(3)}`}</tspan>
      <tspan x={x} dy="1.2em">{`Ampl.N.Match: ${rangeFrequenciesChartBarReferenceLineInfo.amplFromMatchSampleRangeNormalized?.toFixed(3)}`}</tspan>
      <tspan x={x} dy="1.2em">{`Ampl.N.Pttrn: ${rangeFrequenciesChartBarReferenceLineInfo.amplFromMatchPattern?.toFixed(3)}`}</tspan>
    </text>
  )
}

export function SampleRangesChartBarReferenceLabel(props) {
  const {
    fill, value, textAnchor,
    fontSize, viewBox, dy, dx, sampleRangesChartBarReferenceLineInfo
  } = props;
  let x = viewBox.width + viewBox.x + 20;
  const y = viewBox.y - 6;
  let thisPtr = sampleRangesChartBarReferenceLineInfo.thisPtr;

  let offset = 0;
  if (sampleRangesChartBarReferenceLineInfo.fromSample > ((thisPtr?.props?.sampleFrom ?? 10000) + ((thisPtr?.props?.sampleCount ?? 0) / 2)))
  {
    let emSize = 10;

    x -= 18 * emSize;
  }

  let matchFaultToStr = [];
  matchFaultToStr[0] = "None";
  matchFaultToStr[1] = "DecliningEnergy";
  matchFaultToStr[2] = "TooLowEnergyDiff";
  matchFaultToStr[4] = "NearbyIsLower";
  matchFaultToStr[8] = "AboveMatchLimit";
  matchFaultToStr[16] = "ToEarlyInRecording";

  let matchFaultFields = [
    {
      field: "matchFaults",
      matchFaultStr: ""
    },
    {
      field: "diffedMatchFaults",
      matchFaultStr: ""
    },
    {
      field: "diffedMatchFaultsWithIntensity",
      matchFaultStr: ""
    }]; 

  let patternSampleRangeError = sampleRangesChartBarReferenceLineInfo?.patternSampleRangeError;


  matchFaultFields.forEach((matchFaultField) => {
    matchFaultToStr.forEach((x, i) => {
      if (patternSampleRangeError?.[matchFaultField.field] & i) {
        if (matchFaultField.matchFaultStr.length) {
          matchFaultField.matchFaultStr += " ";
        }
        matchFaultField.matchFaultStr += x;
      }
    });
  });


  const nextFromSample = sampleRangesChartBarReferenceLineInfo?.fromSample + sampleRangesChartBarReferenceLineInfo?.samplesPerWindow;


  let ptrnErrorStr = `${patternSampleRangeError?.normalizedError.toFixed(2)} DE:${patternSampleRangeError?.diffedNormalizedError.toFixed(2)} DWIE:${patternSampleRangeError?.diffedNormalizedErrorWithIntensity.toFixed(2)}`;

  let rounds = [0, 1];

  let retVal = rounds.map(round => {
    let styleStr = (round == 0) ? { stroke: "white", "strokeWidth": "0.3em" } : {};

    return (
      <text
        key={round}
        x={x} y={y}
        dy={dy}
        dx={dx}
        fill={fill || "#FFF"}
        style={styleStr}
        fontSize={fontSize || 10}
        textAnchor={textAnchor}
      >
        <tspan x={x} dy="2em" >{`Sample: ${sampleRangesChartBarReferenceLineInfo?.fromSample} - ${nextFromSample}`}</tspan>
        <tspan x={x} dy="1.2em" >{`Ptrn Ampl. Sum: ${(patternSampleRangeError?.amplitudeSum.toFixed(0))}`}</tspan>
        <tspan x={x} dy="1.2em" >{`Ptrn Error: ${ptrnErrorStr}`}</tspan>
        <tspan x={x} dy="1.2em" >{`Ptrn     Faults: ${matchFaultFields[0].matchFaultStr}`}</tspan>
        <tspan x={x} dy="1.2em" >{`Ptrn D   Faults: ${matchFaultFields[1].matchFaultStr}`}</tspan>
        <tspan x={x} dy="1.2em" >{`Ptrn DWI Faults: ${matchFaultFields[2].matchFaultStr}`}</tspan>
      </text>

    );
  });

  return (<>{retVal}</>);

}


class MyContextMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      xPos: "0px",
      yPos: "0px",
      showMenu: false,
      activeSampleRangeIsLocked: false,
    }
  }


  componentDidMount() {
    //document.addEventListener("click", this.handleClick);
    document.getElementById(this.props.parentId).addEventListener("contextmenu", this.handleContextMenu);
  }

  componentWillUnmount() {
    //document.removeEventListener("click", this.handleClick);
    document.getElementById(this.props.parentId).removeEventListener("contextmenu", this.handleContextMenu);
  }


  handleClick = (e) => {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    } 
  }

  handleContextMenu = (e) => {
    e.preventDefault();
    this.setState({
      xPos: `${e.pageX}px`,
      yPos: `${e.pageY}px`,
      showMenu: !this.state.showMenu,
    });
  }

  handleAnchorActivateRange = () => {
    let nextActiveSampleRangeIsLocked = !this.state.activeSampleRangeIsLocked;
    this.setState({
      showMenu: false,
      activeSampleRangeIsLocked: nextActiveSampleRangeIsLocked,
    });
    this.props.onSampleRangeActivate?.(nextActiveSampleRangeIsLocked);
  }

  render() {
    const { showMenu, xPos, yPos } = this.state;

    if (showMenu) {
      let iconClass = this.state.activeSampleRangeIsLocked ? "far fa-check-square"  : "far fa-square";
      return (
        <ul className="dropdown-menu pl-1 pr-1" role="menu" aria-labelledby="dropdownMenu" style={{ display: "block", left: xPos, top: yPos }}  >
          <a onClick={this.handleAnchorActivateRange} ><i className={`${iconClass}`} ></i><span className="ml-1"> {'Toggle lock active sample range in "Amplitudes over time"'}</span></a>
          <li className="divider"></li>
        </ul>
      );
    }
    else {
      return null;
    }
  }
}


export class SpectrumRenderer extends Component {
  uri = null;
  newlyMounted = false;

  componentDidMount() {
    this.newlyMounted = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;

    if (nextProps.recordingId) {
      if (this.props.recordingId != nextProps.recordingId
        || this.props.width != nextProps.width
        || this.props.height != nextProps.height
        || this.props.lowerFrequency != nextProps.lowerFrequency
        || this.props.upperFrequency != nextProps.upperFrequency
        || this.props.windowType != nextProps.windowType
        || this.props.patternId != nextProps.patternId
        || this.newlyMounted
      ) {
        this.newlyMounted = false;
        this.uri = `${Utils.getApiBaseUrl()}/analyzedRecordings/${encodeURI(nextProps.recordingId)}?windowType=${nextProps.windowType}`
          + "&width=" + nextProps.width
          + "&height=" + nextProps.height
          + "&lowerFrequency=" + nextProps.lowerFrequency
          + "&upperFrequency=" + nextProps.upperFrequency
          + "&patternId" + nextProps.patternId
          ;
        shouldUpdate = true;
        this.props.reportChartSize?.({
          width: this.props.width,
          height: this.props.height,

        });
      }
    }
    else {
      this.uri = null;
    }

    return shouldUpdate;
  }


  render() {
    console.log('SpectrumRenderer.render BEGIN: ' + this.uri);

    return (
      <g>
        <image href={this.uri} preserveAspectRatio="none" x={this.props.x} y={this.props.y} height={this.props.height} width={this.props.width} />
      </g >
    );
  }

}


export class FrequencyChartMgr extends Component {
  frequencySelectionRef = React.createRef();
  user = new UserMgr().getCurrentUser();


  constructor(props) {
    super(props);
    let upperFrequency = 100000;
    let matchLimit = 1;
    let diffedMatchLimit = 1;
    let minMatchCount = 1;
    let maxMatchCount = 10000;
    let matchSampleRangeCount = 1;

    this.state = {
      pattern: null,
      analyzedRecording: null,

      lowerFrequency: this.user.settings.recording.frequenciesOverTime.minHertz,
      upperFrequency: upperFrequency,

      strMatchSampleRangeFromValues: "",
      matchSampleRangeFromValues: [],
      patternSampleRangeIndexes: [],

      strMatchSampleRangeCount: "" + matchSampleRangeCount,
      matchSampleRangeCount: matchSampleRangeCount,

      shiftRefIndex: 0,
      patternComment: "",
      patternCommentWanted: "",

      matchLimit: matchLimit,
      strMatchLimit: "" + matchLimit,

      diffedMatchLimit: diffedMatchLimit,
      strDiffedMatchLimit: "" + diffedMatchLimit,

      minMatchCount: minMatchCount,
      strMinMatchCount: "" + minMatchCount,
      maxMatchCount: maxMatchCount,
      strMaxMatchCount: "" + maxMatchCount,

      selectedSampleRange: null,
      windowType: "hanning",
      modalCreatePatternVisible: false,

      modalCreatePatternName: "",

      activeSampleRange: null,
      activeFrequencyIndex: 0,
      activeSampleRangeIsLocked: false,

      errorMethod: "meanSumOfDiffs",

      rangeFrequenciesChartBarReferenceLineInfo: null,
      sampleRangesChartBarReferenceLineInfo: null,
      showSpectrum: false
    };
    console.log('FrequencyChartMgr created.');
  }

  _chartHeight = 200;
  

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;
    if (this.props.recordingId != nextProps.recordingId
      || this.props.patternId != nextProps.patternId
      || this.props.sampleFrom != nextProps.sampleFrom) {
      //populate samples will fix the update
      this.populateData(nextProps.recordingId, this.state.windowType, nextProps.patternId, nextProps.pattern);
    }
    else {
      shouldUpdate = true;
      if (this.props.soundDataChartReferenceLinePosition != nextProps.soundDataChartReferenceLinePosition) {

        let selectedSampleRange = this.state.analyzedRecording?.frequencySpace.sampleRanges.findLast(
          x => nextProps.soundDataChartReferenceLinePosition >= x.fromSample);

        let patternSampleRangeError = this.state.pattern?.matchResult.fromSamplesToSampleRangeErrors[selectedSampleRange?.fromSample];


        let sampleRangesChartBarReferenceLineInfo = {
          thisPtr: this,
          fromSample: selectedSampleRange?.fromSample ?? 0,
          patternSampleRangeError: patternSampleRangeError,
          samplesPerWindow: this.state.analyzedRecording?.frequencySpace.samplesPerWindow,
        };


        this.setState({
          selectedSampleRange: selectedSampleRange,
          activeSampleRange: selectedSampleRange,
          sampleRangesChartBarReferenceLineInfo,
        });
      }
    }

    return shouldUpdate;
  }

  componentDidMount() {
    //this.propspatternId = 1;
    //this.populateData(this.props.recordingId, this.state.windowType, this.props.patternId, this.props.pattern);
    document.addEventListener("keyup", this.handleKeyUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp, false);
  }


  handleKeyUp = (e) => {
    if (e?.target?.nodeName == 'BODY') {
      //filters away input etc
      let selectedSampleRange = this.state.selectedSampleRange;

      let sampleRanges = this.state.analyzedRecording?.frequencySpace.sampleRanges;
      if (e?.key == 'ArrowRight') {
        //try find next that isReal

        selectedSampleRange = sampleRanges.find(x => {
          return x.index > (selectedSampleRange?.index ?? 0);
        });
      }
      if (e?.key == 'ArrowLeft') {
        selectedSampleRange = sampleRanges.findLast(x => {
          return x.index < (selectedSampleRange?.index ?? 1);
        });
      }
      if (selectedSampleRange != null) {
        this.handleSampleRangesChartClick({
          activePayload: [{
            payload: {
              sampleRange: selectedSampleRange
            }
          }]
        });
      }
    }
  }



  handleRefSampleChange = (e) => {
    this.setState({
      strMatchSampleRangeFromValues: e.target.value
    });
  }

  handleInputChangeMatchSampleRangeCount = (e) => {
    this.setState({
      strMatchSampleRangeCount: e.target.value
    });
  }


  handleUpdateFreqChange = async (e) => {
    let strMatchSampleRangeFromValues = this.state.strMatchSampleRangeFromValues.split(",").filter((x) => parseInt(x) != NaN);

    let strMatchSampleRangeCount = (parseInt(this.state.strMatchSampleRangeCount) == NaN) ? ("" + this.state.matchSampleRangeCount) : this.state.strMatchSampleRangeCount;



    let strMatchLimit = (parseFloat(this.state.strMatchLimit) == NaN) ? ("" + this.state.matchLimit) : this.state.strMatchLimit;
    let strDiffedMatchLimit = (parseFloat(this.state.strDiffedMatchLimit) == NaN) ? ("" + this.state.diffedMatchLimit) : this.state.strDiffedMatchLimit;
    let strMinMatchCount = (parseFloat(this.state.strMinMatchCount) == NaN) ? ("" + this.state.minMatchCount) : this.state.strMinMatchCount;
    let strMaxMatchCount = (parseFloat(this.state.strMaxMatchCount) == NaN) ? ("" + this.state.maxMatchCount) : this.state.strMaxMatchCount;


    let matchSampleRangeFromValues = strMatchSampleRangeFromValues.map((x) => parseInt(x));

    let matchSampleRangeCount = parseFloat(strMatchSampleRangeCount);
    if (matchSampleRangeCount < 1) {
      matchSampleRangeCount = 1;
    }
    if (matchSampleRangeCount > 100) {
      matchSampleRangeCount = 100;
    }

    let patternComment = this.state.patternCommentWanted;


    let matchLimit = parseFloat(strMatchLimit);
    if (matchLimit < 0) {
      matchLimit = 0;
    }
    if (matchLimit > 10) {
      matchLimit = 10;
    }

    let diffedMatchLimit = parseFloat(strDiffedMatchLimit);
    if (diffedMatchLimit < 0) {
      diffedMatchLimit = 0;
    }
    if (diffedMatchLimit > 10) {
      diffedMatchLimit = 10;
    }

    let minMatchCount = parseFloat(strMinMatchCount);
    if (minMatchCount < 1) {
      minMatchCount = 1;
    }
    if (minMatchCount > 1000) {
      minMatchCount = 1000;
    }

    let maxMatchCount = parseFloat(strMaxMatchCount);
    if (maxMatchCount < 1) {
      maxMatchCount = 1;
    }
    if (maxMatchCount > 1000) {
      maxMatchCount = 1000;
    }



    let lowerFrequency = 0;
    let upperFrequency = 0;
    {
      let maxFrequency = (this.state.analyzedRecording.recording.sampleRate / 2);
      let state = await this.frequencySelectionRef?.current?.fromWantedToState(maxFrequency);
      lowerFrequency = state.lowerFrequency;
      upperFrequency = state.upperFrequency;
    }


    matchSampleRangeFromValues = matchSampleRangeFromValues.filter((x) => x > 0 || (x + this.state.analyzedRecording.samplesPerWindow) <= (this.state.analyzedRecording.recording.sampleCount));


    let patternSampleRangeIndexes = matchSampleRangeFromValues.map((x) => {
      return (this.state.analyzedRecording?.frequencySpace.sampleRanges ?? []).findLastIndex((y, i) => {
        return (x <= y.fromSample)
      }) ?? null
    });

    patternSampleRangeIndexes = patternSampleRangeIndexes.filter(x => x != null);


    this.setState({
      patternSampleRangeIndexes: patternSampleRangeIndexes,
      matchSampleRangeFromValues: matchSampleRangeFromValues,

      matchSampleRangeCount: matchSampleRangeCount,
      strMatchSampleRangeCount: "" + matchSampleRangeCount,

      patternComment: patternComment,
      patternCommentWanted: patternComment,

      matchLimit: matchLimit,
      strMatchLimit: "" + matchLimit,

      diffedMatchLimit: diffedMatchLimit,
      strDiffedMatchLimit: "" + diffedMatchLimit,

      minMatchCount: minMatchCount,
      strMinMatchCount: "" + minMatchCount,
      maxMatchCount: maxMatchCount,
      strMaxMatchCount: "" + maxMatchCount,

      strMatchSampleRangeFromValues: matchSampleRangeFromValues.map(x => ("" + x)).join(", "),
      lowerFrequency : lowerFrequency,
      upperFrequency: upperFrequency,
    });



  }




  chartY_start = 5;

  

  
  handleSampleRangesChartMouseMove = (e) => {
    let i = 0;
    if (e) {
      if (e?.activePayload?.[0].payload?.sampleRange != null) {
        let sampleRange = e.activePayload[0]?.payload ?.sampleRange;

        let activeSampleRange = this.state.activeSampleRangeIsLocked ? this.state.activeSampleRange : sampleRange;

        let patternSampleRangeError = this.state.pattern?.matchResult.fromSamplesToSampleRangeErrors[activeSampleRange?.fromSample];


        let sampleRangesChartBarReferenceLineInfo = {
          thisPtr: this,
          fromSample: activeSampleRange?.fromSample ?? 0,
          patternSampleRangeError: patternSampleRangeError,
          samplesPerWindow : this.state.analyzedRecording?.frequencySpace.samplesPerWindow,
        };



        let frequency = Math.floor(((1 - ((e.chartY - this.chartY_start) / (this.chartSize?.height ?? 1))) * (this.state.upperFrequency - this.state.lowerFrequency)) + this.state.lowerFrequency);
        let fromFrequencies = this.state.analyzedRecording?.frequencySpace.fromFrequencies ?? [];
        let frequencyIndex = fromFrequencies.findIndex((x, i) => (x >= frequency) && x < (fromFrequencies[i + 1] ?? (this.state.analyzedRecording.recording.sampleRate / 2)));

        if (frequencyIndex != -1) {

          if (this.state.activeSampleRange != activeSampleRange || this.state.activeFrequencyIndex != frequencyIndex) {
            if (activeSampleRange != null && !this.state.activeSampleRangeIsLocked) {
              this.props?.onSampleRangesChartActiveSampleRangeChange({
                sampleRange: activeSampleRange,
                samplesPerWindow: this.state.analyzedRecording?.frequencySpace.samplesPerWindow,
                pattern: this.state.pattern,
              });
            }
          }

          this.setState({
            activeSampleRange: activeSampleRange,
            activeFrequencyIndex: frequencyIndex,
            sampleRangesChartBarReferenceLineInfo,
          });
        }
      }
    }
  }



  handleWindowTypeChange = (e) => {
    let windowType = e.target.value;
    this.setState({
      windowType: windowType,
    });
    this.populateData(this.props.recordingId, windowType, this.props.patternId, null)
  };


  handleShowHideCreatePatternDlg = (e) => {
    this.setState({
      modalCreatePatternVisible: !this.state.modalCreatePatternVisible
    });
  }

  handleCreatePatternNameChange = (e) => {
    let modalCreatePatternName = e?.target?.value;
    this.setState({
      modalCreatePatternName: modalCreatePatternName,
    });
  }

  chkChangedPatternComment = (e) => {
    this.setState({
      patternCommentWanted: e?.target?.checked ? "" : null,
    });

  }

  handleInputPatternCommentChange = (e) => {
    this.setState({
      patternCommentWanted: e?.target?.value ?? this.state.patternCommentWanted,
    });
  }

  handleInputMatchLimitChange = (e) => {
    this.setState({
      strMatchLimit: e?.target?.value ?? this.state.strMatchLimit,
    });
  }

  handleInputDiffedMatchLimitChange = (e) => {
    this.setState({
      strDiffedMatchLimit: e?.target?.value ?? this.state.strDiffedMatchLimit,
    });
  }

  handleInputMinMatchCountChange = (e) => {
    this.setState({
      strMinMatchCount: e?.target?.value ?? this.state.strMinMatchCount,
    });
  }

  handleInputMaxMatchCountChange = (e) => {
    this.setState({
      strMaxMatchCount: e?.target?.value ?? this.state.strMaxMatchCount,
    });
  }

  handleBtnSavePattern = async (e) => {
    let pattern = {
      id: this.state.patternId,
      name: this.state.modalCreatePatternName,
      lowerFrequency: this.state.lowerFrequency,
      upperFrequency: this.state.upperFrequency,
      windowType: this.state.windowType,
      matchLimit: this.state.matchLimit,
      diffedMatchLimit: this.state.diffedMatchLimit,
      minMatchCount: this.state.minMatchCount,
      maxMatchCount: this.state.maxMatchCount,
      sources: [{
        recordingId: this.props.recordingId,
        sampleRanges: this.state.patternSampleRangeIndexes.map(x => {
          let sampleRanges = this.state.analyzedRecording.frequencySpace.sampleRanges;
          return { fromSample: sampleRanges[x].fromSample};
        })
      }]
    };

    let url = `${Utils.getApiBaseUrl()}/patterns/${pattern.id}`;

    try {
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(pattern),
      });

      //load and set inputs so we see all is ok
      this.handleBtnLoadPattern();
    }
    catch {
      alert(`Failed creating pattern ${this.state.modalCreatePatternName}`);
    }

  }


  async loadPattern(patternId, pattern) {


    let analyzedRecording = this.state.analyzedRecording;
    let url = `${Utils.getApiBaseUrl()}/patterns/${patternId}?recordingId=${analyzedRecording?.recording?.id}`;


    try {
      if (patternId != pattern?.id) {
        let pattern = await fetch(url, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        }).then(response => {
          // Pass the JSON formatted body to the next handler
          if (response.ok === true) {
            let contentLength = response.headers.get('Content-Length');
          }
          return response.json();
        });
      }

      let patternSampleRanges = pattern?.sources?.find(x => x.recording.id == analyzedRecording?.recording.id)?.sampleRanges ?? [];


      let maxAmplitude = 0;
      let matchingSampleRanges = [];
      if (pattern?.matchResult?.sampleRangeErrors) { //create a hash fromSample -> result
        let fromSamplesToSampleRangeErrors = [];
        pattern?.matchResult.sampleRangeErrors?.forEach(x => {
          fromSamplesToSampleRangeErrors[x.fromSample] = x;
          maxAmplitude = Math.max(maxAmplitude, x.amplitudeSum);
          if (x.matchFaults === 0 || x.diffedMatchFaults === 0 || x.diffedMatchFaultsWithIntensity === 0) {
            matchingSampleRanges.push(x);
          }
        });
        pattern.matchResult["matchingSampleRanges"] = matchingSampleRanges;

        pattern.matchResult["fromSamplesToSampleRangeErrors"] = fromSamplesToSampleRangeErrors;
      }
      pattern.matchResult["maxAmplitude"] = maxAmplitude;

      if (pattern?.normalizedFreqAmplSets) { //create a hash fromSample -> result
        let maxAmpl = 0;
        Object.keys(pattern.normalizedFreqAmplSets).forEach(i => {
          let freqAmplSet = pattern.normalizedFreqAmplSets[i];
          if (freqAmplSet.ampl > maxAmpl) {
            maxAmpl = freqAmplSet.ampl;
          }
          if (freqAmplSet.diffedAmpl > maxAmpl) {
            maxAmpl = freqAmplSet.diffedAmpl;
          }

        });
        pattern["maxNormalizedAmpl"] = maxAmpl;
        pattern["fromFreqIndexToNormalizedFreqAmplSets"] = pattern?.normalizedFreqAmplSets;
      }


      let patternSampleRangeIndexes = patternSampleRanges.map(x => {
        return (analyzedRecording.frequencySpace.sampleRanges ?? []).findLastIndex((y, i) => {
          return (x.fromSample >= y.fromSample);
        });
      });

      let matchSampleRangeFromValues = patternSampleRanges.map((y) => {
        //debugger;
        return y.fromSample;
      });

      let strMatchSampleRangeFromValues = matchSampleRangeFromValues.join(", ");
      let matchSampleRangeCount = pattern.matchSampleRangeCount ?? 1;


      this.setState({
        modalCreatePatternName: pattern.id,

        lowerFrequency: pattern.lowerFrequency,

        upperFrequency: pattern.upperFrequency,

        windowType: pattern.windowType,

        patternComment: pattern.comment,
        patternCommentWanted: "" + pattern.comment,

        matchLimit: pattern.matchLimit,
        strMatchLimit: "" + pattern.matchLimit,

        diffedMatchLimit: pattern.diffedMatchLimit,
        strDiffedMatchLimit: "" + pattern.diffedMatchLimit,

        minMatchCount: pattern.minMatchCount,
        strMinMatchCount: "" + pattern.minMatchCount,

        maxMatchCount: pattern.maxMatchCount,
        strMaxMatchCount: "" + pattern.maxMatchCount,

        matchSampleRangeFromValues: matchSampleRangeFromValues,
        strMatchSampleRangeFromValues: strMatchSampleRangeFromValues,
        patternSampleRangeIndexes: patternSampleRangeIndexes,

        matchSampleRangeCount: matchSampleRangeCount,
        strMatchSampleRangeCount: "" + matchSampleRangeCount,

        pattern: pattern,
      });
    }
    catch (e) {
      alert(`Failed loading pattern ${this.state.modalCreatePatternName}`);
    }
  }

  handleBtnLoadPattern = async (e) => {



    try {
      await this.loadPattern();
      this.handleShowHideCreatePatternDlg(e);
    }
    catch(e) {
      alert(`Failed loading pattern ${this.state.modalCreatePatternName}`);
    }
  }

  handleBtnShiftRefFrequency = async (e) => {
    await this.setState({
      shiftRefIndex: this.state.shiftRefIndex + ((e.target.value == '+') ? 1 : -1),
    });
  }


  chartSize = null;

  handleSpectrumRendererReportChartSize = (size) => {
    this.chartSize = size;
  };


  handleMyContextMenuSampleRangeActivate = (activeSampleRangeIsLocked) => {
    let i = 0
    this.setState({
      activeSampleRangeIsLocked: activeSampleRangeIsLocked,
    })


  }


  handleFrequenciesChartMouseMove = (e) => {

    if (e != null) {
      let payload = e.activePayload?.[0]?.payload;
      //let matchResult =
      this.setState({
        rangeFrequenciesChartBarReferenceLineInfo: {
          thisPtr: this,
          frequency: e.activeLabel,

          ampl: payload?.ampl ?? 0,
          amplNormalized: payload?.amplNormalized ?? 0,

          diffedAmpl: payload?.diffedAmpl ?? 0,
          diffedAmplNormalized: payload?.diffedAmplNormalized ?? 0,

          amplFromMatchSampleRangeNormalized: payload?.amplFromMatchSampleRangeNormalized ?? 0,
          amplFromMatchPattern: this.frequenciesChart_getValueForPatternNormalizedAmpl(payload) ?? 0,
          amplFromDiffedMatchPattern: this.frequenciesChart_getValueForPatternNormalizedDiffedAmpl(payload) ?? 0,
          fromSample: this.state.selectedSampleRange?.fromSample,
          samplesPerWindow: this.state.analyzedRecording?.frequencySpace.samplesPerWindow,
        }
      });
    }

  };


  fromFreqToPatternNormalizedAmpl = (fromFreqIndex) => {
    let fromFreqIndexToNormalizedFreqAmplSets = this.state.pattern?.fromFreqIndexToNormalizedFreqAmplSets;
    let retVal = 0;
    if (fromFreqIndexToNormalizedFreqAmplSets) {
      retVal = fromFreqIndexToNormalizedFreqAmplSets[fromFreqIndex]?.ampl;
    }
    return retVal;
  }

  fromFreqToPatternNormalizedDiffedAmpl = (fromFreqIndex) => {
    let fromFreqIndexToNormalizedFreqAmplSets = this.state.pattern?.fromFreqIndexToNormalizedFreqAmplSets;
    let retVal = 0;
    if (fromFreqIndexToNormalizedFreqAmplSets) {
      retVal = fromFreqIndexToNormalizedFreqAmplSets[fromFreqIndex]?.diffedAmpl;
    }
    return retVal;
  }


  frequenciesChart_getValueForPatternNormalizedAmpl = (x) => {
    return x?.thisPtr?.fromFreqToPatternNormalizedAmpl(x?.fromFrequencyIndex) ?? 0;
  }

  frequenciesChart_getValueForPatternNormalizedDiffedAmpl = (x) => {
    return x?.thisPtr?.fromFreqToPatternNormalizedDiffedAmpl(x?.fromFrequencyIndex) ?? 0;
  }

  frequenciesChart_renderPatternSampleRanges = () => {
    let sampleRanges = this.state.pattern?.sources?.find(x => x.recording.id == this.state.analyzedRecording?.recording?.id)?.sampleRanges ?? [];

    let outerRetVal = sampleRanges.map((x, i) => {
      let retVal = (
        <>
          <ReferenceArea yAxisId="left" x1={x.usedFromSample ?? 0}
            x2={(x.usedFromSample != null) ? (x.usedFromSample + this.state.analyzedRecording?.frequencySpace.samplesPerWindow) : 0}
            stroke="#F00" fillOpacity={0.2} strokeOpacity={0.5} />

          <ReferenceLine yAxisId="left" type="number" x={x.fromSample} stroke="#F00" strokeDasharray="3 3" />
        </>
      );
      return retVal;
    });
    return outerRetVal;
  }


  sampleRangesChart_renderPatternAmplScaled = (x) => {
    let retVal = null;
    let pattern = x?.sampleRange?.thisPtr?.state.pattern;
    let fromSamplesToSampleRangeErrors = pattern?.matchResult.fromSamplesToSampleRangeErrors;
    let sampleRange = x.sampleRange;

    let matchingRanges = pattern?.matchResult.matchingSampleRanges?.filter(y => {
      let isMatch = y.diffedMatchFaultsWithIntensity === 0;
      if (isMatch) {
        let firstFromSample = y.fromSample + pattern.aroundSumSets[0].fromSampleOffset;
        let lastFromSample = y.fromSample + pattern.aroundSumSets[pattern.aroundSumSets.length - 1].fromSampleOffset;
        isMatch = (sampleRange.fromSample >= firstFromSample && sampleRange.fromSample <= lastFromSample);
      }
      return isMatch;
    });

    if (matchingRanges?.length) {
      for (let i = 0; i < pattern.aroundSumSets.length; i++)
      {
        let offset = sampleRange.fromSample - matchingRanges[0].fromSample;

        if (pattern.aroundSumSets[i].fromSampleOffset == offset) {
          //0.05 to get it a bit raised above zeror
          retVal = pattern.aroundSumSets[i].sumNormalized + 0.05;
          break;
        }

      }
    }
    return retVal;
  }


  sampleRangesChart_renderPatternMatchResultAmplScaled = (x) => {
    let retVal = 0;
    let pattern = x?.sampleRange?.thisPtr?.state.pattern;
    let fromSamplesToSampleRangeErrors = pattern?.matchResult.fromSamplesToSampleRangeErrors;
    if (fromSamplesToSampleRangeErrors) {
      retVal = fromSamplesToSampleRangeErrors?.[x?.sampleRange?.fromSample]?.amplitudeSum;
      //make scaled values for the chart
      retVal = ((retVal / pattern.matchResult.maxAmplitude) * ((pattern.upperFrequency/2) - pattern.lowerFrequency)) + pattern.lowerFrequency;
    }
    return retVal;
  }

  sampleRangesChart_renderPatternMatchResultDiffedAmplScaled = (x) => {
    let retVal = 0;
    let pattern = x?.sampleRange?.thisPtr?.state.pattern;
    let fromSamplesToSampleRangeErrors = pattern?.matchResult.fromSamplesToSampleRangeErrors;
    if (fromSamplesToSampleRangeErrors) {
      retVal = fromSamplesToSampleRangeErrors?.[x?.sampleRange?.fromSample]?.diffedAmplitudeSum;
      //make scaled values for the chart
      retVal = ((retVal / pattern.matchResult.maxAmplitude) * ((pattern.upperFrequency / 2) - pattern.lowerFrequency)) + pattern.lowerFrequency;
    }
    return retVal;
  }



  sampleRangesChart_renderPatternError = (x) => {
    let fromSamplesToSampleRangeErrors = x?.sampleRange?.thisPtr?.state.pattern?.matchResult.fromSamplesToSampleRangeErrors;
    let retVal = fromSamplesToSampleRangeErrors?.[x?.sampleRange?.fromSample]?.normalizedError;
    return retVal;
  }

  sampleRangesChart_renderPatternDiffedError = (x) => {
    let fromSamplesToSampleRangeErrors = x?.sampleRange?.thisPtr?.state.pattern?.matchResult.fromSamplesToSampleRangeErrors;
    let retVal = fromSamplesToSampleRangeErrors?.[x?.sampleRange?.fromSample]?.diffedNormalizedError;
    return retVal;
  }

  sampleRangesChart_renderPatternDiffedWithIntensityError = (x) => {
    let fromSamplesToSampleRangeErrors = x?.sampleRange?.thisPtr?.state.pattern?.matchResult.fromSamplesToSampleRangeErrors;
    let retVal = fromSamplesToSampleRangeErrors?.[x?.sampleRange?.fromSample]?.diffedNormalizedErrorWithIntensity;
    return retVal;
  }

  sampleRangesChart_renderLowerFrequency = (x) => {
    let retVal = x?.sampleRange?.thisPtr?.state.lowerFrequency;
    return retVal;
  }

  frequenciesChart_renderAmplNormalized = (x) => {
    let selectedSampleRange = x?.thisPtr?.state.selectedSampleRange;
    let pattern = x?.thisPtr?.state.pattern;
    
    let amplitudeSum = pattern?.matchResult.fromSamplesToSampleRangeErrors[selectedSampleRange?.fromSample]?.amplitudeSum;

    let retVal = 0;
    if ((x?.fromFrequency >= pattern?.lowerFrequency) && (x?.fromFrequency < pattern?.upperFrequency)) {
      retVal = selectedSampleRange?.frequencyIndexToAmplitude?.[x.fromFrequencyIndex] / amplitudeSum;
    }
    return retVal;
  }

  frequenciesChart_renderDiffedAmplNormalized = (x) => {
    let selectedSampleRange = x?.thisPtr?.state.selectedSampleRange;
    let pattern = x?.thisPtr?.state.pattern;

    let amplitudeSum = pattern?.matchResult.fromSamplesToSampleRangeErrors[selectedSampleRange?.fromSample]?.diffedAmplitudeSum;

    let retVal = 0;
    if ((x?.fromFrequency >= pattern?.lowerFrequency) && (x?.fromFrequency < pattern?.upperFrequency)) {
      retVal = selectedSampleRange?.frequencyIndexToDiffedAmplitude?.[x.fromFrequencyIndex] / amplitudeSum;
    }
    return retVal;
  }


  render() {

    let user = new UserMgr().getCurrentUser();


    let analyzedRecording = this.state.analyzedRecording;
    let frequencySpace = this.state.analyzedRecording?.frequencySpace;

    let savePatternEnabled = ((this.state.pattern?.sources?.[0].recording.id ?? this.state.analyzedRecording?.recording?.id) == this.state.analyzedRecording?.recording?.id);

    let disableUpdateBtn = `${this.state.lowerFrequency}` == (this.frequencySelectionRef?.current?.state.strLowerFrequencyWanted ?? "")
      && `${this.state.upperFrequency}` == (this.frequencySelectionRef?.current?.state.strLowerFrequencyWanted ?? "")
      && (this.state.matchSampleRangeFromValues?.join(',') ?? "") == this.state.strMatchSampleRangeFromValues
      && (parseInt(this.state.matchSampleRangeCount) ?? "") == this.state.strMatchSampleRangeCount
      && (this.state.patternComment == this.state.patternCommentWanted)
      && (parseFloat(this.state.strMatchLimit) == this.state.matchLimit)
      && (parseFloat(this.state.strDiffedMatchLimit) == this.state.diffedMatchLimit)
      && (parseFloat(this.state.strMinMatchCount) == this.state.minMatchCount)
      && (parseFloat(this.state.strMaxMatchCount) == this.state.maxMatchCount)
      ;


    let retVal = (
      <fieldset id="fieldSetFrequencies" className="border rounded border-primary p-1">
        <legend className="h6 ml-1 w-16em">Frequencies over time</legend>

        <div className="container">
          <div className="row">
            <button className="form-control-sm btn-primary mr-1" onClick={this.handleReAnalyzeRecording} >
              Reload analyzed recording
            </button>
            <div>
              <label style={{ marginRight: "0.2em" }}>Window:</label>
              <select style={{ width: "7em", marginRight: "1em" }} className="form-control-sm" defaultValue="hanning" onChange={this.handleWindowTypeChange}>
                <option value="hanning" >Hanning</option>
                <option value="none">None</option>
              </select>
            </div>
            <div className="w-100">
              <fieldset className="border rounded border-primary p-1">
                <legend className="h6 ml-1 w-16em">Pattern match settings</legend>
                <span className="text-nowrap">
                  <label style={{ marginRight: "0.2em" }}>Match Samples:</label>
                  <input id="matchSampleRangeFromValues" style={{ "width": "10em"}} className="mr-2" type="text" value={this.state.strMatchSampleRangeFromValues} onChange={this.handleRefSampleChange} />
                </span>
                <span className="text-nowrap">
                  <label style={{ marginRight: "0.2em" }}>Range count:</label>
                  <input className="mr-2 w-2em" type="text" value={this.state.strMatchSampleRangeCount} onChange={this.handleInputChangeMatchSampleRangeCount} />
                </span>
                <span className="text-nowrap mr-2">
                  <label className="mr-1">Cmt:</label>
                  <input type="checkbox" className="mr-1" checked={this.state.patternCommentWanted != null} onChange={this.chkChangedPatternComment} aria-label="Checkbox for following text input" />
                  <input type="text" className="input w-8em text-nowrap" disabled={this.state.patternCommentWanted == null || !savePatternEnabled} value={this.state.patternCommentWanted} onChange={this.handleInputPatternCommentChange} />
                </span>
                <span className="text-nowrap">
                  <label className="mr-1">Match Limit:</label>
                  <input style={{ "width": "3em" }} className="mr-2" type="text" value={this.state.strMatchLimit} onChange={this.handleInputMatchLimitChange} />
                </span>
                <span className="text-nowrap">
                  <label className="mr-1">Diffed match Limit:</label>
                  <input style={{ "width": "3em" }} className="mr-2" type="text" value={this.state.strDiffedMatchLimit} onChange={this.handleInputDiffedMatchLimitChange} />
                </span>
                <button id="createPattern" style={{ marginRight: "0.2em" }} className="form-control-sm btn-primary" onClick={this.handleShowHideCreatePatternDlg} >Save/Load pattern ({this.state.pattern?.id})</button>
                <span className="text-nowrap">
                  <label className="mr-1">Min match count:</label>
                  <input style={{ "width": "3em" }} className="mr-2" type="text" value={this.state.strMinMatchCount} onChange={this.handleInputMinMatchCountChange} />
                </span>
                <span className="text-nowrap">
                  <label className="mr-1">Max match count:</label>
                  <input style={{ "width": "3em"}} className="mr-2" type="text" value={this.state.strMaxMatchCount} onChange={this.handleInputMaxMatchCountChange} />
                </span>
              </fieldset>
              <Modal isOpen={this.state.modalCreatePatternVisible} toggle={this.handleShowHideCreatePatternDlg} >
                <ModalHeader toggle={this.handleShowHideCreatePatternDlg}>
                  Create pattern
                </ModalHeader>
                <ModalBody>
                  <ReactStrapLabel >Pattern Name:</ReactStrapLabel>
                  <Input onChange={this.handleCreatePatternNameChange} value={this.state.modalCreatePatternName}></Input>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={!savePatternEnabled} color={savePatternEnabled ? "primary" : "warning"} onClick={this.handleBtnSavePattern} className="mr-1">
                    Save {!savePatternEnabled ? "(Disabled)" : ""}
                  </Button>
                  <Button color="primary" onClick={this.handleBtnLoadPattern} className="mr-1">
                    Load
                  </Button>
                  <Button onClick={this.handleShowHideCreatePatternDlg}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <div className="mt-1 w-100">
              <FrequencySelection ref={this.frequencySelectionRef} lowerFrequency={this.state.lowerFrequency} upperFrequency={this.state.upperFrequency} />
              <div>
                <button disabled={disableUpdateBtn} style={{ "width": "5em" }} className={(disableUpdateBtn ? "" : "bg-warning ") + "form-control-sm btn-primary mr-1"} onClick={this.handleUpdateFreqChange}>
                  Update
                </button>
                <button className="form-control-sm btn-primary mr-1" onClick={this.handleBtnShiftRefFrequency} value="-"> - </button>
                {this.state.shiftRefIndex}
                <button className="form-control-sm btn-primary mr-1 ml-1" onClick={this.handleBtnShiftRefFrequency} value="+"> + </button>
              </div>
            </div>
          </div>
        </div>
        <div id="divSampleRangeChart">
          <MyContextMenu parentId="divSampleRangeChart" onSampleRangeActivate={this.handleMyContextMenuSampleRangeActivate} />
          <ResponsiveContainer width="100%" height={this._chartHeight}  >

            <LineChart width={500} height={0} data={frequencySpace?.sampleRangesWithThisPtr} onClick={this.handleSampleRangesChartClick} onMouseMove={this.handleSampleRangesChartMouseMove} >
              <XAxis allowDataOverflow={true}  domain={[this.props.sampleFrom ?? 0, (this.props.sampleFrom ?? 0) + (this.props.sampleCount ?? 0) - 1]} dataKey="sampleNumber" type="number" />
              <YAxis yAxisId="left" domain={[this.state.lowerFrequency, this.state.upperFrequency]} type="number" />
              <YAxis yAxisId="right" orientation="right" type="number" />
              {
                (analyzedRecording != null && this.state.showSpectrum)
                  ? <ReferenceArea yAxisId="left" shape={<SpectrumRenderer
                    recordingId={this.props.recordingId} lowerFrequency={this.state.lowerFrequency} upperFrequency={this.state.upperFrequency}
                    windowType={this.state.windowType} reportChartSize={this.handleSpectrumRendererReportChartSize}
                  />} />

                  : ""
              }
              < Line yAxisId="left" dataKey={this.sampleRangesChart_renderLowerFrequency} isAnimationActive={false} type="linear" stroke="#000" dot={false}
                comment={"just used to get a reference on where we are"}
              />
              < Line yAxisId="right" domain={[0, 2]} dataKey={this.sampleRangesChart_renderPatternError} isAnimationActive={false} type="linear" stroke="#DD0" dot={false} />
              < Line yAxisId="right" domain={[0, 2]} dataKey={this.sampleRangesChart_renderPatternDiffedError} isAnimationActive={false} type="linear" stroke="darkorange"
                dot={<SampleRangeMatchesDot thisPtr={this} errorType={ErrorType.DiffedError} />} />
              < Line yAxisId="right" domain={[0, 2]} dataKey={this.sampleRangesChart_renderPatternDiffedWithIntensityError} isAnimationActive={false} type="linear" stroke="blue"
                dot={<SampleRangeMatchesDot thisPtr={this} errorType={ErrorType.DiffedWithIntensityError} />} />

              
              < Line yAxisId="right" dataKey={this.sampleRangesChart_renderPatternAmplScaled} isAnimationActive={false} type="linear" stroke="orange" dot={false} />
              < Line yAxisId="left" dataKey={this.sampleRangesChart_renderPatternMatchResultAmplScaled} isAnimationActive={false} type="linear" stroke="green" dot={false} />
              < Line yAxisId="left" dataKey={this.sampleRangesChart_renderPatternMatchResultDiffedAmplScaled} isAnimationActive={false} type="linear" strokeDasharray="3 3"  stroke="green" dot={false} />
              
              <ReferenceLine yAxisId="left" type="number" x={this.state.selectedSampleRange?.fromSample} stroke="black" />
              <ReferenceLine yAxisId="left" type="number" x={this.state.selectedSampleRange?.fromSample + frequencySpace?.samplesPerWindow} stroke="black" strokeDasharray="3 3" />
              <ReferenceLine yAxisId="right" y={this.state.matchLimit} stroke="red" strokeDasharray="3 3" />
              <ReferenceLine yAxisId="right" y={this.state.diffedMatchLimit} stroke="red" strokeDasharray="3 3" />
              <ReferenceLine yAxisId="right" y={this.state.pattern?.matchLimit} stroke="#FF0" strokeDasharray="3 3" />
              <ReferenceLine yAxisId="right" y={this.state.pattern?.diffedMatchLimit} stroke="#FF0" strokeDasharray="3 3" />
              <ReferenceLine yAxisId="left" type="number" x={this.state.sampleRangesChartBarReferenceLineInfo?.fromSample} stroke="green"
                label={<SampleRangesChartBarReferenceLabel fill={this.state.showSpectrum ? "#FFF" : "#000"} sampleRangesChartBarReferenceLineInfo={this.state.sampleRangesChartBarReferenceLineInfo} />}>
              </ReferenceLine>
              <ReferenceLine yAxisId="left" type="number" x={(this.state.sampleRangesChartBarReferenceLineInfo?.fromSample ?? 0) + (this.state.sampleRangesChartBarReferenceLineInfo?.samplesPerWindow ?? 0)} stroke="green" strokeDasharray="3 3" >
              </ReferenceLine>
              {this.frequenciesChart_renderPatternSampleRanges()}
            </LineChart>
          </ResponsiveContainer>
        </div>

        <ResponsiveContainer width="100%" height={this._chartHeight}>
          <LineChart width={500} height={0} data={frequencySpace?.fromFrequenciesWithThisPtr} onMouseMove={this.handleFrequenciesChartMouseMove} >
            <XAxis allowDataOverflow={true} dataKey="fromFrequencyExtra" type="number" domain={[this.state.lowerFrequency, this.state.upperFrequency]} />
            <YAxis yAxisId="left" type="number" />
            <YAxis allowDataOverflow={true} yAxisId="right" type="number" orientation="right" domain={[-0.01, (1.2 * this.state.pattern?.maxNormalizedAmpl) ??  0.03]} />

            < Line yAxisId="right" name="Ampl." dataKey={this.frequenciesChart_renderAmplNormalized} isAnimationActive={false} type="linear" stroke="#0F0" dot={false} />

            < Line yAxisId="right" name="Diffed Ampl." dataKey={this.frequenciesChart_renderDiffedAmplNormalized} legend="amplitude" isAnimationActive={false} type="linear" stroke="DarkGreen" dot={false} />

            < Line yAxisId="right" name="Ptrn Ampl." dataKey={this.frequenciesChart_getValueForPatternNormalizedAmpl} isAnimationActive={false} type="linear" stroke="red" dot={false} />

            < Line yAxisId="right" name="Ptrn Diffed Ampl." dataKey={this.frequenciesChart_getValueForPatternNormalizedDiffedAmpl} isAnimationActive={false} type="linear" stroke="darkred" dot={false} />
            <Legend verticalAlign="center" height={36} />
            <ReferenceLine yAxisId="left" type="number" x={this.state.rangeFrequenciesChartBarReferenceLineInfo?.frequency} stroke="black"
              label={<FrequenciesChartBarReferenceLabel thisPtr={this} rangeFrequenciesChartBarReferenceLineInfo={this.state.rangeFrequenciesChartBarReferenceLineInfo} />}>
            </ReferenceLine>

            <ReferenceLine yAxisId="right" type="number" y="0" stroke="#ccc" />

          </LineChart>
        </ResponsiveContainer>
      </fieldset>
    );

    return retVal;
  }

  handleSampleRangesChartClick = (e) => {

    if (e?.activePayload[0]?.payload?.sampleRange) {
      let sampleRange = e?.activePayload[0]?.payload?.sampleRange;

      if (this.props.onSampleRangesChartClick) {
        this.props.onSampleRangesChartClick(sampleRange);
      }



      let patternSampleRangeError = this.state.pattern?.matchResult.fromSamplesToSampleRangeErrors[sampleRange.fromSample];


      let rangesFrequenciesChartBarReferenceLineInfo = {
        thisPtr: sampleRange.thisPtr,
        fromSample: sampleRange?.fromSample ?? 0,
        patternSampleRangeError: patternSampleRangeError,
        samplesPerWindow: this.state.analyzedRecording?.frequencySpace.samplesPerWindow,
      };


      sampleRange.thisPtr.setState({
        selectedSampleRange: sampleRange,
        activeSampleRange: sampleRange,
        rangesFrequenciesChartBarReferenceLineInfo: rangesFrequenciesChartBarReferenceLineInfo,
      });


    }
  }


  handleReAnalyzeRecording = async () => {

    let url = `${Utils.getApiBaseUrl()}/analyzedRecordings/${encodeURI(this.props.recordingId)}?windowType=${this.state.windowType}`
      + "&patternId=" + this.props.patternId;

    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    });
    this.populateData(this.props.recordingId, this.state.windowType, null, null);
  }






  async populateData(id, windowType, patternId, pattern) {
    let patternParam = (patternId != null) ? ("&patternId=" + patternId) :  "";
    let uri = `${Utils.getApiBaseUrl()}/analyzedRecordings/${encodeURI(id)}?windowType=${windowType}`
      + patternParam
      + "";
    let analyzedRecording = null;
    try {

      let response = await fetch(uri);
      analyzedRecording = await response.json();

      let upperFrequency = this.state.upperFrequency;

      let recording = analyzedRecording.recording;

      upperFrequency = (upperFrequency < (recording.sampleRate / 2)) ? upperFrequency : (recording.sampleRate / 2);

      let frequencySpace = analyzedRecording.frequencySpace;

      const samplesPerWindow = frequencySpace.samplesPerWindow;
      const samplesPerStep = frequencySpace.samplesPerStep;

      frequencySpace.sampleRangesWithThisPtr = [];
      let i = 0;
      for (let fromSample = 0; fromSample < analyzedRecording.recording.sampleCount; fromSample += analyzedRecording.frequencySpace.samplesPerStep) {
        frequencySpace.sampleRangesWithThisPtr.push({
          sampleNumber: fromSample + (samplesPerWindow / 2) - ((samplesPerStep) / 2),
          sampleRange: {
            fromSample: fromSample,
            thisPtr: this,
            index: i,
          }
        });
        frequencySpace.sampleRangesWithThisPtr.push({
          sampleNumber: fromSample + (samplesPerWindow / 2) - ((samplesPerStep) / 2) + (samplesPerStep - 1),
          sampleRange: {
            fromSample: fromSample,
            thisPtr: this,
            index: i++
          }
        });
      }

      frequencySpace.sampleRanges.forEach((x, i) => {
        x.thisPtr = this;
        x.isRealSampleRange = true;

        frequencySpace.sampleRangesWithThisPtr[x.index * 2].sampleRange = x;
        frequencySpace.sampleRangesWithThisPtr[(x.index * 2) + 1].sampleRange = x;
      });

      let fromFrequenciesWithThisPtr = [];
      let fromFrequenciesToIndexes = [];
      frequencySpace.fromFrequencies.forEach((x, i) => {
        fromFrequenciesWithThisPtr.push({ fromFrequencyIndex: i, fromFrequency: x, fromFrequencyExtra: x,  thisPtr: this });
        fromFrequenciesWithThisPtr.push({ fromFrequencyIndex: i, fromFrequency: x, fromFrequencyExtra: frequencySpace.fromFrequencies[i + 1] ?? frequencySpace.fromFrequencies[i] , thisPtr: this });
        fromFrequenciesToIndexes[x] = i;
      });

      frequencySpace.fromFrequenciesWithThisPtr = fromFrequenciesWithThisPtr;
      frequencySpace.fromFrequenciesToIndexes = fromFrequenciesToIndexes;

      await this.setState({
        analyzedRecording: analyzedRecording,
        upperFrequency: upperFrequency,
        modalCreatePatternName: patternId ? patternId : this.state.modalCreatePatternName
      });

      if (patternId) {
        this.loadPattern(patternId, pattern);
      }

    }
    catch(e)
    {
      console.log('FrequencyChartMgr.populateData: No analyzed recording');
    }


  }
}
